import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SessionNotesService } from "src/app/services/session-notes.service";

@Component({
  selector: "app-frozen-confirmation-dialog",
  templateUrl: "./frozen-confirmation-dialog.component.html",
  styleUrls: ["./frozen-confirmation-dialog.component.css"],
})
export class FrozenConfirmationDialogComponent {
  serviceResponseProgress = false;
  id: number;
  name: string;
  action: string = "freeze";

  srpColor = "primary";
  srpMode: string = "indeterminate";
  srpValue = 60;

  constructor(
    private sessionNotesService: SessionNotesService,
    private dialogref: MatDialogRef<FrozenConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.id = data.id;
    this.name = data.name;
    this.action = data.action;
  }

  eventCallYes() {
    this.serviceResponseProgress = true;
    var isFrozen = true;
    var message = "Successfully froze Session Note";

    if (this.action === "restore") {
      isFrozen = false;
      message = "Successfully restored Session Note";
    }
    this.sessionNotesService
      .freeze_restore_session_notes(this.id, isFrozen)
      .subscribe((result) => {
        this.serviceResponseProgress = false;
        this.dialogref.close(message);
      });
  }

  eventCallNo() {
    this.dialogref.close();
  }
}
