import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddParticipantSummaryComponent } from "./components/add-participant-summary/add-participant-summary.component";
import { AddSessionNoteComponent } from "./components/add-session-note/add-session-note.component";
import { LoginComponent } from "./components/login/login.component";
import { ParticipantSummaryPreviewComponent } from "./components/participant-summary-preview/participant-summary-preview.component";
import { ParticipantSummaryComponent } from "./components/participant-summary/participant-summary.component";
import { SessionNotesPreviewComponent } from "./components/session-notes-preview/session-notes-preview.component";
import { SessionNotesComponent } from "./components/session-notes/session-notes.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "session-notes", component: SessionNotesComponent },
  { path: "session-notes/:id", component: AddSessionNoteComponent },
  {
    path: "session-notes/preview/:id",
    component: SessionNotesPreviewComponent,
  },
  { path: "participant-summary", component: ParticipantSummaryComponent },
  {
    path: "participant-summary/:id",
    component: AddParticipantSummaryComponent,
  },
  {
    path: "participant-summary/preview/:id",
    component: ParticipantSummaryPreviewComponent,
  },
  { path: "statistics", component: StatisticsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
