<div class="logo-container">
  <img
    src="assets/images/BF-RGB-big-BF.png"
    class="logo"
    routerLink="/session-notes"
  />
</div>

<mat-nav-list>
  <a id="menu-item" href="/session-notes">
    <div class="menu-item-container">
      <img src="assets/images/Notes-Icon.png" class="menu-logo" />
      <p class="menu-name">Key Takeaways</p>
    </div>
  </a>

  <a id="menu-item" href="/session-notes?is_frozen=true">
    <div class="menu-item-container">
      <img
        src="assets/images/Freeze.png"
        class="menu-logo"
        style="padding-bottom: 15px"
      />
      <p class="menu-name">Frozen</p>
    </div>
  </a>

  <a id="menu-item" href="/participant-summary">
    <div class="menu-item-container">
      <mat-icon
        class="menu-logo-participant participant-logo"
        style="padding-bottom: 15px"
        >people_outline</mat-icon
      >
      <p class="menu-name-participant">Participant Summary</p>
    </div>
  </a>

  <a id="menu-item" routerLink="/statistics" style="z-index: 100">
    <div class="menu-item-container">
      <img src="assets/images/Stats.png" class="menu-logo" />
      <p class="menu-name">Statistics</p>
    </div>
  </a>

  <div id="menu-item" (click)="logout()">
    <div class="menu-item-container">
      <img src="assets/images/sign-out.png" class="menu-logo" />
      <p class="menu-name">Logout</p>
    </div>
  </div>
</mat-nav-list>
