<mat-sidenav-container class="main-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="main-sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <div class="main-sidenav-content">
    <h1>Statistics</h1>
    <div class="filters">
      <b>Filters:</b>&nbsp;&nbsp;Start Date:&nbsp;
      <input
        [owlDateTime]="dt1"
        [owlDateTimeTrigger]="dt1"
        class="add-form-input font-21 rubik"
        placeholder="Start Date"
        id="startDate"
        (dateTimeChange)="onChange()"
      />
      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
      &nbsp;&nbsp;End Date:&nbsp;
      <input
        [owlDateTime]="dt2"
        [owlDateTimeTrigger]="dt2"
        class="add-form-input font-21 rubik"
        placeholder="End Date"
        id="endDate"
        (dateTimeChange)="onChange()"
      />
      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
      &nbsp;&nbsp;Client:&nbsp;
      <span class="" *ngIf="clients.length > 0">
        <input
          id="clientInfo"
          type="text"
          placeholder="All Clients"
          aria-label=""
          [formControl]="clientMyControl"
          [matAutocomplete]="auto"
          class="add-form-input font-21 rubik"
          (keyup)="filterClientList()"
          (click)="filterClientList()"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredClients || clients"
            [value]="option.name"
            (click)="selectClient(option.id)"
            class="autocomplete-option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </span>
      <!-- <mat-button-toggle-group class="header-height">
        <mat-button-toggle class="split-button" style="padding: 4px 0px">
          Select
        </mat-button-toggle>
        <mat-button-toggle class="split-button">
          <img
            src="assets/images/down-arrow-select.png"
            class="mat-drop-down-icon"
          />
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-menu #dropdownMenuYear="matMenu" *ngIf="clients">
        <button
          *ngFor="let client of clients"
          (click)="filter(client.id)"
          mat-menu-item
        >
          {{ client.name }}
        </button>
      </mat-menu> -->
    </div>
    <!-- charts -->
    <!-- <div class="box left">
      <div id="chart-container" *ngIf="lineChartData.length > 0">
        <div class="graph-header">
          Line Chart
          <div class="tooltip">
            <mat-icon class="icons">info</mat-icon>
            <span class="tooltiptext"
              >Displays the date wise count of Session Notes created between the
              specified date range.
            </span>
          </div>
        </div>
        <canvas
          baseChart
          [chartType]="lineChartType"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [colors]="lineChartColors"
          [options]="lineChartOptions"
        ></canvas>
      </div>
    </div> -->
    <div class="box left" *ngIf="barChartLabels.length > 0">
      <div class="graph-header">
        No. of Session Notes created
        <!-- <div class="tooltip">
          <mat-icon class="icons">info</mat-icon>
          <span class="tooltiptext"
            >Displays the date wise count of Session Notes, Moderators &
            Sections created between the specified date range.
          </span>
        </div> -->
      </div>
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType"
        [colors]="barChartColors"
      >
      </canvas>
    </div>
    <!-- <div class="box left">
      <div style="display: block" *ngIf="pieChartData.length > 0">
        <div class="graph-header">
          Pie Chart
          <div class="tooltip">
            <mat-icon class="icons">info</mat-icon>
            <span class="tooltiptext"
              >Displays the client wise count of Session Notes created between
              the specified date range.
            </span>
          </div>
        </div>
        <canvas
          baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend"
        >
        </canvas>
      </div>
    </div> -->
    <div class="left" *ngIf="barChartLabels.length > 0">
      <div class="graph-header">
        <!-- Tabular representation of No. of Session Notes created -->
        <!-- <div class="tooltip">
          <mat-icon class="icons">info</mat-icon>
          <span class="tooltiptext"
            >Displays the date wise count of Session Notes, Moderatos & Sections
            created between the specified date range.
          </span>
        </div> -->
      </div>
      <ag-grid-angular
        *ngIf="columnDefs.length > 0"
        class="ag-theme-balham box left"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [pagination]="true"
        [paginationAutoPageSize]="true"
      >
      </ag-grid-angular>
    </div>
    <div *ngIf="barChartLabels.length == 0">No Records Found</div>
  </div>
</mat-sidenav-container>
