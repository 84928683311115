import { environment } from "src/environments/environment";

const BACKEND_URL = environment.baseUrl;

export class API {
  static BACKEND_URL = BACKEND_URL;
  static USER_LOGIN = `${BACKEND_URL}user/signin`;

  // session notes endpoints
  static CREATE_SESSION_NOTE = `${BACKEND_URL}notes/create`;
  static UPDATE_SESSION_NOTE(id: number) {
    return `${BACKEND_URL}notes/${id}/update`;
  }
  static DELETE_SECTION_NOTE(id: number) {
    return `${BACKEND_URL}notes/${id}/delete`;
  }
  static GET_SESSION_NOTE(id: number) {
    return `${BACKEND_URL}notes/${id}`;
  }
  static GET_SESSION_NOTES = `${BACKEND_URL}notes/list`;
  static PREVIEW_NOTES(id: number) {
    return `${BACKEND_URL}notes/${id}/download`;
  }
  static FREEZE_RESTORE_SESSION_NOTES(id: number) {
    return `${BACKEND_URL}notes/${id}/update`;
  }
   

  // moderator endpoints
  static ADD_MODERATOR = `${BACKEND_URL}moderator/create`;
  static GET_MODERATOR = `${BACKEND_URL}moderator/list`;

  // client endpoints
  static CREATE_CLIENT = `${BACKEND_URL}client/create`;
  static ADDITION_LOGO = `${BACKEND_URL}notesv2/additional_logo`;

  static UPDATE_CLIENT = `${BACKEND_URL}client/update/`

  static GET_ADDITION_LOGOS() {
    return `${BACKEND_URL}notesv2/additional_logo`;
  }
  static GET_ADDITION_LOGO(id: number) {
    return `${BACKEND_URL}notesv2/additional_logo/${id}`;
  }
  static GET_CLIENTS() {
    return `${BACKEND_URL}client/list`;
  }
  static GET_CLIENT(id: number) {
    return `${BACKEND_URL}client/list?id=${id}`;
  }
  static SEARCH_CLIENT(name: string) {
    return `${BACKEND_URL}client/list?name=${name}`;
  }

  // section endpoint
  static GET_SECTION_NOTE(notes_id: number) {
    return `${BACKEND_URL}notes/section/list?notes_id=${notes_id}`;
  }

  // statistics
  static GET_SESSION_NOTES_GRAPH_DATA(
    startDate: string,
    endDate: string,
    clientId?: number
  ) {
    return `${BACKEND_URL}stats/date_count?start_date=${startDate}&end_date=${endDate}&client_id=${clientId}`;
  }

  static GET_CLIENT_NOTES_GRPAH_DATA(startDate: string, endDate: string) {
    return `${BACKEND_URL}stats/client_count?start_date=${startDate}&end_date=${endDate}`;
  }

  // participant endpoints
  static CREATE_PARTICIPANT = `${BACKEND_URL}notes/participant/create`;
  static UPDATE_PARTICIPANT(id?: number) {
    return `${BACKEND_URL}notes/participant/${id}/update`;
  }
  static DELETE_PARTICIPANT(id: number) {
    return `${BACKEND_URL}notes/particpant/${id}/delete`;
  }
  static GET_PARTICIPANT(id: number) {
    return `${BACKEND_URL}notes/participant/${id}`;
  }
  static GET_PARTICIPANTS = `${BACKEND_URL}notes/participant/list`;

  static DELETE_PARTICPANT(id: number) {
    return `${BACKEND_URL}notes/participant/${id}/delete`;
  }

  static GET_EVENT_TYPES = `${BACKEND_URL}notes/event_type/list`;
}
