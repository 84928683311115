import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalLogoService } from 'src/app/services/additional-logo.service';
import { ClientInfoService } from 'src/app/services/client-info.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { aboutAdditionalLogo } from 'src/app/constants/models';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-additional-logo',
  templateUrl: './additional-logo.component.html',
  styleUrls: ['./additional-logo.component.css']
})
export class AdditionalLogoComponent implements OnInit {
  uploadForm: FormGroup = new FormGroup({});
  clientLogo: string | null | undefined = null;
  additionLogo:any
  additionaldata:aboutAdditionalLogo
  changeDetail:boolean
  info:any
  additionalLogoList:any
  OperationClient:number = 1
  public Editor = ClassicEditor;
  existingLogo:any
  selectedadditionalLogo:any
  selectedUser: aboutAdditionalLogo | null = null;
  additionalLogoListFilter:any
  autoComplete =  new FormControl();

  user:any
  constructor(
    private dialogref: MatDialogRef<AdditionalLogoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private additionalLogoService: AdditionalLogoService,
    private snackBar: SnackbarService,
    private formBuilder: FormBuilder
  ) { 
    this.additionaldata = data.additionalLogo;
    this.changeDetail = false
    this.selectedadditionalLogo = data.additionalLogo?.id;
 
    console.log(data, this.additionaldata )
  }

  ngOnInit(): void {
    // var name = document.getElementById("additionLogo") as HTMLInputElement;
    var website = document.getElementById("additionalLogoWebsite") as HTMLInputElement;
    var logo = document.getElementById("clientLogo") as HTMLInputElement;
    // name.value = (this.additionaldata?.name || "").trim();
    // this.info = new FormControl(this.additionaldata?.description);
    website.value = this.additionaldata?.url || "";
    this.additionLogo = this.additionaldata?.logo_url;
    this.uploadForm = this.formBuilder.group({
      clientLogo: [""],
    });
    if (localStorage.getItem("user") != null) {
       this.user = JSON.parse(localStorage["user"]);
    }
    
    const httpParams = { params: { page_size: 10000 } };
    this.additionalLogoService.getAllAdditionalLogo(httpParams).subscribe((data:any)=>{
      if(data.results){
        this.additionalLogoList = data.results
        
      }
    })

    this.additionalLogoListFilter = this.autoComplete.valueChanges.pipe(
      startWith(''),
      map(value =>  this._filter(value)   )
    );
  }
  checkIfClientChanged(newName: string) {
    return this.additionaldata?.name !== newName.trim();
  }





  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(this.additionalLogoList , value)
    return this.additionalLogoList?.filter((option:any) => option.url.toLowerCase() == filterValue);
  }
  

  clientChange(e:any){

    let sClient = this.additionalLogoList?.find((a:any )=>a.id == e.value )
    // console.log(e.value , sClient)
    // sClient &&  ( this.info = new FormControl(sClient.description));
    sClient &&  (  this.additionLogo = sClient.logo)
    var website = document.getElementById(
      "additionalLogoWebsite"
    ) as HTMLInputElement;
    // const name = document.getElementById("additionLogo") as HTMLInputElement;
    sClient &&  ( website.value = sClient.url);
    // sClient &&  ( name.value = sClient.name);
    sClient &&  ( this.existingLogo = sClient )
    sClient &&  ( this.additionaldata = sClient )
  }

  closeDialog() {
 
    this.dialogref.close();
  }

  triggerUpload() {
    const upload = document.getElementById("clientLogoId") as HTMLInputElement;
    upload.click();
  }

  uploadFiles(event: any) {
    const file = event.target.files[0];
    this.uploadForm.get("clientLogo")?.setValue(file);
    this.uploadForm.patchValue({
      logo: file,
    });

    this.uploadForm.get("clientLogo")?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = (e) => {
      this.additionLogo = `${e.target?.result}`;
    };
    reader.readAsDataURL(file);
  }
  operationChange(e:any){
    // console.log(e.value)
    if(e.value == '1'){
        this.reset()
    }
  }
  reset() {
    // const name = document.getElementById("additionLogo") as HTMLInputElement;
    const website = document.getElementById(
      "additionalLogoWebsite"
    ) as HTMLInputElement;
    // name.value = "";
    this.info = new FormControl("");
    website.value = "";
    this.existingLogo = undefined
  }

  createClient(){
    // var name = (document.getElementById("additionLogo") as HTMLInputElement)
    // .value;
  var website = (
    document.getElementById("additionalLogoWebsite") as HTMLInputElement
  ).value;
  if ( website != "" ) {
    
    const formData = new FormData();
    formData.append("logo", this.uploadForm.get("clientLogo")?.value);
    formData.append("url", website);
    formData.append("name", 'abc');
    // formData.append("description",  this.info.value);
    formData.append("created_by",  this.user.id);
    this.additionalLogoService.createAdditionalLogo(formData).subscribe((result: any) => {
      if (result?.status == false) {
        this.snackBar.snackBarDanger(result.message);
      } else {
        this.snackBar.snackBarSuccess("Client added successfully.");
        result.saved = true;
        this.dialogref.close(result);
      }
    });
  }else {
    this.snackBar.snackBarDanger(
      "Cannot add additional logo. Please make sure to add url."
    );
  }
  }

  updateClient(){
    var name = (document.getElementById("additionLogo") as HTMLInputElement)
    .value;
  var website = (
    document.getElementById("additionalLogoWebsite") as HTMLInputElement
  ).value;
    console.log(this.additionaldata.logo)
  const formData = new FormData();
  formData.append("logo", this.additionaldata.logo);
  formData.append("url", website);
  formData.append("name", name.trim());
  formData.append("description",  this.info.value);
  formData.append("modified_by",  this.user.id);
    this.additionalLogoService.updateAdditionalLogo(formData, this.additionaldata.id ).subscribe((result: any) => {
      if (result?.status == false) {
        this.snackBar.snackBarDanger(result.message);
      } else {
        this.snackBar.snackBarSuccess("Additional Logo Updated successfully.");
        result.saved = true;
        this.dialogref.close(result);
      }
    })
  }

  done() {
    // var logo = (document.getElementById("clientLogoId") as HTMLInputElement)
    //   .value;
    this.closeDialog()
    this.dialogref.close(this.existingLogo);
  }

}
