<mat-sidenav-container class="main-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="main-sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <div class="main-sidenav-content">
    <h1>Add/Edit Participant Summary</h1>
    <div class="mat-tab-container">
      <div class="mat-tab-content-details">
        <h2><p class="red-text rubik capital">Fill the form</p></h2>
        <div class="left full-width">

          <div style="padding-bottom: 20px;" class="event-type-select">
            <span class="add-form-input-label rubik">Session Note:</span>
          <mat-form-field appearance="fill" style="width: 80%;">
            <mat-label>{{noteId != 'add' ? '': 'Select one'}}</mat-label>
            <mat-select [(ngModel)]="noteId" name="eventtype">
              <mat-option *ngFor="let note of notes" [value]="note.id">
                {{note.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>


          <!-- <input
            id="notes"
            type="text"
            placeholder="Search for Session Notes for which participant summary does not exists"
            aria-label=""
            [formControl]="notesMyControl"
            [matAutocomplete]="auto"
            class="add-form-input font-21 rubik"
            (keyup)="filterSessionNotes()"
          />

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredNotes || notes"
              [value]="option.title"
              (click)="selectNote(option.id)"
              class="autocomplete-option"
            >
              {{ option.title }}
            </mat-option>
          </mat-autocomplete> -->


          <div
            class="add-form no-padding"
            [formGroup]="myGroup"
            *ngIf="sections"
          >
            <div *ngFor="let participant of sections; let i = index">
              
              <input
                matInput
                type="text"
                placeholder="Title"
                id="sectiontitleid_{{ i + 1 }}"
                class="add-form-input font-21 rubik"
                formControlName="sectiontitle_{{ i + 1 }}"
                style="margin-top: 20px"
              />
              <span class="add-form-input-label">Content ({{ i + 1
              }})</span>
              <ckeditor
                #editor
                id="sectionparagraphid_{{ i + 1 }}"
                [editor]="Editor"
                formControlName="sectionparagraph_{{ i + 1 }}"
              ></ckeditor>

          
            </div>
          </div>

          <div class="left full-width">
            <div class="section-panel" id="section-panel"></div>
            <button
              _ngcontent-dxo-c17=""
              class="action-button mat-raised-button mat-button-base"
              mat-raised-button=""
              style="width: 340px"
            >
              <span class="mat-button-wrapper accordion" (click)="addSection()">
                Add Participant Summary
                <img _ngcontent-dxo-c17="" src="assets/images/add-new.png"
              /></span>
              <div class="mat-button-ripple mat-ripple" matripple=""></div>
              <div class="mat-button-focus-overlay"></div></button
            >&nbsp;&nbsp;

            <button
              _ngcontent-dxo-c17=""
              class="action-button mat-raised-button mat-button-base"
              mat-raised-button=""
              style="width: 380px"
            >
              <span
                class="mat-button-wrapper accordion"
                (click)="removeSection()"
              >
                Remove Participant Summary
                <img _ngcontent-dxo-c17="" src="assets/images/minus.jpg"
              /></span>
              <div class="mat-button-ripple mat-ripple" matripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </button>
          </div>
        </div>
        <div class="left full-width">
          <div class="full-width left">
            <button
              mat-button
              class="done-btn right"
              style="width: 140px"
              (click)="preview(true)"
            >
              Download
            </button>
            <button mat-button class="save-btn right" (click)="save()">
              SAVE
            </button>
            <!-- <button mat-button class="reset-btn right" (click)="reset()">
              RESET
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
