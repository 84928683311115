import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "../constants/urls";

@Injectable({
  providedIn: "root",
})
export class ClientInfoService {
  constructor(private http: HttpClient) {}

  createClient(data: any) {
    return this.http.post<any>(API.CREATE_CLIENT, data);
  }

  updateClient(data:any, Id:number){
    return this.http.patch<any>(API.UPDATE_CLIENT+Id, data)
  }

  getClient(id: number) {
    return this.http.get<any>(API.GET_CLIENT(id));
  }

  getAllClient(params:any) {
    return this.http.get<any>(API.GET_CLIENTS(), params);
  }

  searchClient(name: string) {
    return this.http.get<any>(API.SEARCH_CLIENT(name));
  }
}
