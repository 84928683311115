import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { GridApi } from "ag-grid-community";
import { ChartDataSets, ChartType } from "chart.js";
import * as moment from "moment";
import { Label } from "ng2-charts";
import { SnackbarService } from "src/app/services/snackbar.service";
import { StatisticsService } from "src/app/services/statistics.service";
// import { Observable } from "rxjs";
// import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.css"],
})
export class StatisticsComponent implements OnInit, AfterViewInit {
  gridApi?: GridApi;

  // filters
  startDate?: string;
  endDate?: string;
  clients: any[] = [];
  notesModsSectionsData: any;
  selectedClientId?: number;

  clientMyControl: FormControl = new FormControl();
  filteredClients: any[] = [];
  // filteredOptions?: Observable<string[]>;

  // bar chart
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: any[] = [
    {
      backgroundColor: "#1b2143",
      // backgroundColor: "#c1011a",
    },
  ];
  public barChartOptions: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      ],
    },
  };

  public barChartData: ChartDataSets[] = [];

  // line chart
  // public lineChartType: ChartType = "line";
  // public lineChartOptions: any;
  // public lineChartData: any[] = [];
  // public lineChartLabels: Label[] = [];
  // public lineChartColors: any[] = [];
  // public chartOptions: any;

  // pie chart
  // public pieChartOptions: ChartOptions = {
  //   responsive: true,
  // };
  // public pieChartLabels: Label[] = [];
  // public pieChartData: SingleDataSet = [];
  // public pieChartType: ChartType = "pie";
  // public pieChartLegend = true;
  // public pieChartPlugins = [];

  // grid
  title = "app";
  columnDefs: any[] = [];
  rowData: any = [];
  constructor(
    private statsService: StatisticsService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getClients();
    // const filterFn = this._filter;
    // this.filteredOptions = this.clientMyControl.valueChanges.pipe(
    //   startWith(""),
    //   map((value) => filterFn(value))
    // );
  }

  ngAfterViewInit() {
    this.setDefaultDates();
  }

  filterClientList() {
    this.filteredClients = [];
    if (!this.clientMyControl.value) {
      this.filteredClients = this.clients;
    } else {
      this.clients.forEach((option) => {
        if (
          option.name
            .toLowerCase()
            .includes(this.clientMyControl.value.toLowerCase())
        ) {
          this.filteredClients.push(option);
        }
      });
    }
  }

  selectClient(clientId?: number) {
    this.selectedClientId = clientId;
    this.onChange();
  }

  setDefaultDates() {
    var today = new Date();
    const endDate = document.getElementById("endDate") as HTMLInputElement;
    this.endDate = endDate.value = this.get_ddmmyyyy(today);

    // last n months
    let lastNmonths = new Date(today.setMonth(today.getMonth() - 6));
    const startDate = document.getElementById("startDate") as HTMLInputElement;
    this.startDate = startDate.value = this.get_ddmmyyyy(lastNmonths);

    // get data
    this.getSessionNotesGraphData();
  }

  get_ddmmyyyy(d: any) {
    var dd = String(d.getDate()).padStart(2, "0");
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
  }

  onChange() {
    const startDateElem = (
      document.getElementById("startDate") as HTMLInputElement
    ).value;
    const endDateElem = (document.getElementById("endDate") as HTMLInputElement)
      .value;

    const startDate = moment(startDateElem, "MM/DD/YYYY");
    const endDate = moment(endDateElem, "MM/DD/YYYY");

    const diff = endDate.diff(startDate, "days");
    if (diff < 0) {
      this.snackBarService.snackBarWarning(
        "End date should be greater than Start date."
      );
    } else if (diff < 7) {
      this.snackBarService.snackBarWarning(
        "We recommend to select atleast 7 days, for us to draw the graph properly."
      );
    } else {
      this.resetVariables();
      this.getSessionNotesGraphData();
    }
  }

  resetVariables() {
    // this.pieChartData = [];
    // this.pieChartLabels = [];
    this.columnDefs = [];
    this.rowData = [];
  }

  getClients() {
    const httpParams = { params: { page_size: 5000 } };
    this.statsService.getClients(httpParams).subscribe((data: any) => {
      this.clients = data.results;
      this.clients.push({ id: null, name: "All Clients" });
    });
  }

  // lineChart(labels: [], notes: []) {
  //   this.lineChartData = [
  //     {
  //       data: notes,
  //       label: "Notes",
  //       fill: false,
  //     },
  //   ];
  //   this.lineChartLabels = labels;
  //   this.lineChartColors = [
  //     {
  //       backgroundColor: "rgba(0, 0, 0, 0.2)",
  //       borderColor: "#c1011a",
  //     },
  //   ];
  //   this.lineChartOptions = {
  //     scales: {
  //       yAxes: [
  //         {
  //           ticks: {
  //             beginAtZero: false,
  //             stepSize: 1,
  //           },
  //         },
  //       ],
  //     },
  //   };
  // }

  // pieChart(startDate: string, endDate: string) {
  //   this.statsService
  //     .getClientNotesGraphData(startDate, endDate)
  //     .subscribe((data) => {
  //       this.pieChartLabels = data.labels;
  //       this.pieChartData = data.data;
  //     });
  // }

  getSessionNotesGraphData() {
    const startDateElem = (
      document.getElementById("startDate") as HTMLInputElement
    ).value;
    const endDateElem = (document.getElementById("endDate") as HTMLInputElement)
      .value;

    const startDate = moment(startDateElem, "MM/DD/YYYY").format("YYYY-MM-DD");
    const endDate = moment(endDateElem, "MM/DD/YYYY").format("YYYY-MM-DD");

    this.statsService
      .getSessionNotesGraphData(startDate, endDate, this.selectedClientId || "")
      .subscribe((data: any) => {
        this.notesModsSectionsData = data;
        this.barChartLabels = data.labels;
        this.barChartData = [
          { data: data.notes, label: "Notes" },
          // { data: data.moderators, label: "Moderators" },
          // { data: data.sections, label: "Sections" },
        ];
        // set line chart data as well
        // this.pieChart(startDate, endDate);
        // this.lineChart(data.labels, data.notes);
        this.prepareGridData();
      });
  }

  prepareGridData() {
    for (var i = 0; i <= this.notesModsSectionsData.labels.length; i++) {
      this.rowData.push({
        date: this.notesModsSectionsData.labels[i],
        notes: this.notesModsSectionsData.notes[i],
        // moderators: this.notesModsSectionsData.moderators[i],
        // sections: this.notesModsSectionsData.sections[i],
      });
    }
    this.columnDefs = [
      { headerName: "Date", field: "date", resizable: true, width: "324px" },
      { headerName: "Notes", field: "notes", resizable: true, width: "324px" },
      // {
      //   headerName: "Moderators",
      //   field: "moderators",
      //   resizable: true,
      //   width: "162px",
      // },
      // {
      //   headerName: "Sections",
      //   field: "sections",
      //   resizable: true,
      //   width: "162px",
      // },
    ];
  }
}
