import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
// import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  DURATION = 3000;
  constructor(public _snackBar: ToastrService) {}

  snackBarSuccess(message: string) {
    this._snackBar.success(message);
    // this._snackBar.open(message, "", {
    //   duration: this.DURATION,
    //   panelClass: "alert-success",
    // });
  }
  snackBarInfo(message: string) {
    this._snackBar.info(message);
    // this._snackBar.open(message, "", {
    //   duration: this.DURATION,
    //   panelClass: "alert-info",
    // });
  }
  snackBarWarning(message: string) {
    this._snackBar.warning(message);
    // this._snackBar.open(message, "", {
    //   duration: this.DURATION,
    //   panelClass: "alert-warning",
    // });
  }
  snackBarDanger(message: string) {
    this._snackBar.error(message);
    // this._snackBar.open(message, "", {
    //   duration: this.DURATION,
    //   panelClass: "alert-danger",
    // });
  }
}
