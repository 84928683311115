import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "../constants/urls";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(private http: HttpClient) {}

  getClients(params: any,) {
    return this.http.get<any>(API.GET_CLIENTS(), params);
  }

  getSessionNotesGraphData(startDate: string, endDate: string, clientId?: any) {
    return this.http.get<any>(
      API.GET_SESSION_NOTES_GRAPH_DATA(startDate, endDate, clientId)
    );
  }

  getClientNotesGraphData(startDate: string, endDate: string) {
    return this.http.get<any>(
      API.GET_CLIENT_NOTES_GRPAH_DATA(startDate, endDate)
    );
  }
}
