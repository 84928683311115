<mat-sidenav-container class="main-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="main-sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <div class="progress-spinner" *ngIf="serviceResponseProgress">
    <mat-progress-spinner
      *ngIf="serviceResponseProgress"
      class="progress-spinner-margin"
      [color]="srpColor"
      [value]="srpValue"
    >
    </mat-progress-spinner>
  </div>

  <div class="main-sidenav-content">
    <h1>Browse Session Notes</h1>
    <div class="button-container">
      <mat-form-field
        appearance="outline"
        class="search-form-field"
        style="width: 50%"
      >
        <input
          matInput
          type="text"
          placeholder="Search Session Notes"
          [formControl]="searchName"
          (keyup)="applySessionNotesFilter()"
        />
        <img matSuffix src="assets/images/search.png" class="search-icon" />
      </mat-form-field>

      <div class="dropdown-button-holder">
        <span class="button-seperator">
          <mat-button-toggle-group
            [matMenuTriggerFor]="dropdownMenuYear"
            class="header-height"
          >
            <mat-button-toggle class="split-button" style="padding: 4px 0px">{{
              selectedYear
            }}</mat-button-toggle>
            <mat-button-toggle class="split-button">
              <img
                src="assets/images/down-arrow-select.png"
                class="mat-drop-down-icon"
              />
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-menu #dropdownMenuYear="matMenu">
            <button
              mat-menu-item
              style="color: #0000005e !important; padding: 4px 12px"
            >
              YEAR
            </button>
            <button
              *ngFor="let year of calendarYears"
              (click)="filterYear(year)"
              mat-menu-item
            >
              {{ year }}
            </button>
          </mat-menu>

          
        </span>

        <span
          class="button-seperator"
          [ngClass]="
            selectedYear === 'YEAR' ? 'disabled-div' : 'button-seperator'
          "
        >
          <mat-button-toggle-group
            [matMenuTriggerFor]="dropdownMenuMonth"
            class="header-height"
          >
            <mat-button-toggle class="split-button" style="padding: 4px 0px">{{
              selectedMonth
            }}</mat-button-toggle>
            <mat-button-toggle class="split-button">
              <img
                *ngIf="selectedYear != 'YEAR'"
                src="assets/images/down-arrow-select.png"
                class="mat-drop-down-icon"
              />
              <img
                *ngIf="selectedYear === 'YEAR'"
                src="assets/images/down-arrow-disabled.png"
                class="mat-drop-down-icon"
              />
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-menu #dropdownMenuMonth="matMenu">
            <button
              *ngFor="let month of calendarMonths"
              (click)="filterMonth(month)"
              mat-menu-item
            >
              {{ month }}
            </button>
          </mat-menu>
        </span>

        <!-- [ngClass]="
        selectedYear === 'YEAR' ? 'disabled-div' : 'button-seperator'
      " -->

        <span
          class="button-seperator"
       
        >
          <mat-button-toggle-group
            [matMenuTriggerFor]="dropdownMenuEventType"
            class="header-height"
          >
            <mat-button-toggle class="split-button select-type" style="padding: 4px 0px">
              {{selectedEventType?.name ? selectedEventType?.name:'Type' }}
            </mat-button-toggle>
            <mat-button-toggle class="split-button">

              <img
                src="assets/images/down-arrow-select.png"
                class="mat-drop-down-icon"
              />
            </mat-button-toggle>
          </mat-button-toggle-group>

          <mat-menu #dropdownMenuEventType="matMenu">
            <button
              *ngFor="let event of eventTypes"
              (click)="filterEventType(event)"
              mat-menu-item
            >
              {{ event.name }}
            </button>
          </mat-menu>
        </span>


      </div>

      <span class="button-seperator">
        <button
          class="primary-button header-height"
          (click)="applySessionNotesFilter()"
          mat-raised-button
        >
          FILTER
        </button>
      </span>

      <span class="button-seperator">
        <button
          class="primary-button warning-button header-height"
          (click)="resetFilters()"
          mat-raised-button
        >
          RESET
        </button>
      </span>

      <span class="button-seperator" *ngIf="!isFrozen">
        <button
          mat-raised-button
          class="action-button header-height"
          routerLink="/session-notes/add"
        >
          ADD NEW
          <img src="assets/images/add-new.png" />
        </button>
      </span>
    </div>

    <div *ngIf="sessionNotesResults" class="mat-tab-container">
      <div class="mat-tab-content-details">
        <p *ngIf="sessionNotesResults.count == 0">No Records Found</p>
        <div
          class="booklet-flex-container"
          *ngFor="let note of sessionNotesResults.results"
        >
          <div
            class="booklets"
            [routerLink]="[note.id]"
            style="cursor: pointer"
          >
            <img class="booklet-image" src="assets/images/Clients.png" />
            <img *ngIf="note.logo" class="client-logo-inside-booklet" src="{{ note.logo }}" />
            <div> 
            <!-- <p
              class
              class="booklet-name-condition"
              *ngIf="note.title.length <= 42 && note.title.length > 0"
            >
              {{ note.title }}
            </p>
            <p
              class="booklet-name"
              *ngIf="note.title.length > 42 && note.title.length < 85"
            >
              {{ note.title }}
            </p> -->
            <p class="booklet-name cut-text">{{ note.title }}</p>
            <!-- <p
              class="booklet-created-by-condition"
              *ngIf="note.title.length <= 42 && note.title.length > 0"
            >
              <b
                >{{ note.created_by.first_name }}
                {{ note.created_by.last_name }}</b
              >,
              <span>{{ note.session_scheduled_at | date: "mediumDate" }}</span>
            </p> 
          *ngIf="note.title.length > 42 && note.title.length < 85" -->
            <p class="booklet-created-by" style="margin-left: 5px;">
              <b >

                <mat-icon role="img"
                 class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" 
                 data-mat-icon-type="font">event</mat-icon> {{note.event_type_name ? note.event_type_name : 'No Event Type'}}: 


                <!-- {{ note.created_by.first_name }}
                {{ note.created_by.last_name }} -->
                
                </b
              >
              <span style="margin-left: 10px">{{ note.session_scheduled_at | date: "mediumDate" }}</span>
            </p>
          </div>
          </div>
          <div class="action-button-container" *ngIf="note.is_frozen">
            <button
              mat-raised-button
              class="restore-button"
              (click)="freezeDialog(note.id, note.title, 'restore')"
            >
              Restore
              <img src="assets/images/restore-icon-white.png" />
            </button>
          </div>
          <div class="action-button-container" *ngIf="!note.is_frozen">
            <a
              [routerLink]="['/session-notes/preview', note.id]"
              target="_blank"
            >
              <!-- [queryParams]="{ bklt_id: note.id }" -->
              <button mat-raised-button class="export-button">
                EXPORT
                <img src="assets/images/Export-icon-white.png" />
              </button>
            </a>
            <button
              mat-raised-button
              class="edit-button"
              [routerLink]="['/session-notes/', note.id]"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <button
              mat-button
              (click)="freezeDialog(note.id, note.title, 'freeze')"
              class="freeze-button"
            >
              <img src="assets/images/freeze-icon-white.png" />
            </button>

            <button
              mat-button
              class="freeze-button red"
              (click)="confirmDelete(note.id, note.title)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
        <!-- <div class="paginator-button-container"> -->
        <div class="right">
          <button
            mat-raised-button
            [disabled]="sessionNotesResults.previous === null"
            class="action-button"
            (click)="navigatePage(sessionNotesResults.previous)"
          >
            <mat-icon>navigate_before</mat-icon>PREVIOUS
          </button>
          <button
            mat-raised-button
            [disabled]="sessionNotesResults.next === null"
            class="action-button"
            (click)="navigatePage(sessionNotesResults.next)"
          >
            NEXT
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
