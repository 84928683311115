import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AboutClientResponseModal, aboutClient } from "src/app/constants/models";
import { ClientInfoService } from "src/app/services/client-info.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-about-client",
  templateUrl: "./about-client.component.html",
  styleUrls: ["./about-client.component.css"],
})
export class AboutClientComponent implements OnInit {
  client!: aboutClient;
  selectedclient!: number;
  info = new FormControl("");
  existingClient!: any ;
  clientList:[aboutClient] | undefined
  // for image upload
  uploadForm: FormGroup = new FormGroup({});
  clientLogo: string | null | undefined = null;
  public Editor = ClassicEditor;
  changeDetail:boolean
  page!:number
  page_size!:number
  OperationClient:any 

  constructor(
    private dialogref: MatDialogRef<AboutClientComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private clientInfoService: ClientInfoService,
    private snackBar: SnackbarService,
    private formBuilder: FormBuilder
  ) {
    this.client = data.client;
    this.changeDetail = false
    this.selectedclient = data.client?.id;
  }

  ngOnInit(): void {
    var name = document.getElementById("companyname") as HTMLInputElement;
    var website = document.getElementById("companywebsite") as HTMLInputElement;
    var logo = document.getElementById("clientLogo") as HTMLInputElement;
    name.value = (this.client?.name || "").trim();
    this.info = new FormControl(this.client?.info);
    website.value = this.client?.website || "";
    this.clientLogo = this.client?.logo;
    this.page = 1
    this.page_size =10000
    this.uploadForm = this.formBuilder.group({
      clientLogo: [""],
    });
   
    const httpParams = { params: { page_size: 10000 } };
    this.clientInfoService.getAllClient(httpParams).subscribe((data:any)=>{
      if(data.results){
        this.clientList = data.results
        
      }
    })
  }

  checkIfClientChanged(newName: string) {
    return this.client?.name !== newName.trim();
  }

  checkAndUpdateIfExists() {
    var name = document.getElementById("companyname") as HTMLInputElement;
    var nameChanged = this.checkIfClientChanged(name.value);
    console.log(name, 'input text' )
    if (nameChanged) {
      this.clientInfoService.searchClient(name.value.trim()).subscribe((data) => {
        if (data.results.length >= 1) {
          this.snackBar.snackBarInfo(
            "Please Note  : Client already exists, the same will be updated on saving!"
          );
          const existingClient = data.results[0];
          this.info = new FormControl(existingClient.info);
          this.clientLogo = existingClient.logo;

          var website = document.getElementById(
            "companywebsite"
          ) as HTMLInputElement;
          website.value = existingClient.website;

          this.changeDetail = true
          this.existingClient = existingClient
            
        } else{
          // this.reset()
        }
        
      });
    }
  }

  clientChange(e:any){

    let sClient = this.clientList?.find(a =>a.id == e.value )
    // console.log(e.value , sClient)
    sClient &&  ( this.info = new FormControl(sClient.info));
    sClient &&  (  this.clientLogo = sClient.logo)
    var website = document.getElementById(
      "companywebsite"
    ) as HTMLInputElement;
    const name = document.getElementById("companyname") as HTMLInputElement;
    sClient &&  ( website.value = sClient.website);
    sClient &&  ( name.value = sClient.name);
    sClient &&  ( this.existingClient = sClient )
    sClient &&  ( this.client.id = sClient.id )
  }

  closeDialog() {
    if (this.client) this.client.saved = false;
    this.dialogref.close(this.client);
  }

  triggerUpload() {
    const upload = document.getElementById("clientLogoId") as HTMLInputElement;
    upload.click();
  }

  uploadFiles(event: any) {
    const file = event.target.files[0];
    this.uploadForm.get("clientLogo")?.setValue(file);
    this.uploadForm.patchValue({
      logo: file,
    });

    this.uploadForm.get("clientLogo")?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = (e) => {
      this.clientLogo = `${e.target?.result}`;
    };
    reader.readAsDataURL(file);
  }
  operationChange(e:any){
    // console.log(e.value)
    if(e.value == '1'){
        this.reset()
    }
  }
  reset() {
    const name = document.getElementById("companyname") as HTMLInputElement;
    const website = document.getElementById(
      "companywebsite"
    ) as HTMLInputElement;
    name.value = "";
    this.info = new FormControl("");
    website.value = "";
    this.existingClient = undefined
  }

  createClient(){
    var name = (document.getElementById("companyname") as HTMLInputElement)
    .value;
  var website = (
    document.getElementById("companywebsite") as HTMLInputElement
  ).value;
  if (name != "" && this.info.value != "" && website != "" ) {
    var postData = {
      name: name.trim(),
      info: this.info.value,
      website: website,
    };
    const formData = new FormData();
    formData.append("file", this.uploadForm.get("clientLogo")?.value);
    formData.append("data", JSON.stringify(postData));
    this.clientInfoService.createClient(formData).subscribe((result: any) => {
      if (result?.status == false) {
        this.snackBar.snackBarDanger(result.message);
      } else {
        this.snackBar.snackBarSuccess("Client added successfully.");
        result.saved = true;
        this.dialogref.close(result);
      }
    });
  }else {
    this.snackBar.snackBarDanger(
      "Cannot add client. Please make sure to add Image, Name & Info."
    );
  }
  }

  updateClient(){
    var name = (document.getElementById("companyname") as HTMLInputElement)
    .value;
  var website = (
    document.getElementById("companywebsite") as HTMLInputElement
  ).value;
    var Data = {
      name: name.trim(),
      info: this.info.value,
      website: website,
    };
    const formData = new FormData();
    formData.append("file", this.uploadForm.get("clientLogo")?.value);
    formData.append("data", JSON.stringify(Data));
    this.clientInfoService.updateClient(formData, this.client.id ).subscribe((result: any) => {
      if (result?.status == false) {
        this.snackBar.snackBarDanger(result.message);
      } else {
        this.snackBar.snackBarSuccess("Client Updated successfully.");
        result.saved = true;
        this.dialogref.close(result);
      }
    })
  }

  done() {
    // var logo = (document.getElementById("clientLogoId") as HTMLInputElement)
    //   .value;
    this.closeDialog()
    this.dialogref.close(this.existingClient);
  }
   
}
