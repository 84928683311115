<div class="login-container">
  <div class="login-form-container">
    <h1 class="welcome-text">
      <span style="color: #c1011a">Session</span>
      <span style="color: #383838">Notes</span>
    </h1>
    <input
      matInput
      type="text"
      [formControl]="username"
      placeholder="Username*"
    />
    <br />
    <span
      class="error_msg"
      *ngIf="checkCredentialValidity && !this.username.valid"
      >*Username Field Required</span
    >
    <br />
    <input
      matInput
      [type]="hidePassword ? 'password' : 'text'"
      [formControl]="password"
      placeholder="Password*"
    />
    <button
      class="visiblity-button"
      mat-icon-button
      matSuffix
      (click)="hidePassword = !hidePassword"
    >
      <mat-icon style="color: #cbcbcb;"
        >{{ hidePassword ? "visibility_off" : "visibility" }}
      </mat-icon>
    </button>
    <br />
    <span
      class="error_msg"
      *ngIf="checkCredentialValidity && !this.password.valid"
      >*Password Field Required</span
    >
    <br />
    <mat-slide-toggle
      (change)="remember_action()"
      [formControl]="rememberCredential"
      class="remember-toggle"
    >
      REMEMBER ME
    </mat-slide-toggle>

    <button mat-raised-button class="login-button-md" (click)="login()">
      LOGIN
    </button>
  </div>
</div>
