<div class="hide-page"></div>
<mat-dialog-content>
  <div class="messageBody-centered-add-session-note">
    <mat-icon class="close-btn" (click)="closeDialog()">cancel</mat-icon>
    <span class="modal-head montserrat"> Add Client Info</span>
    <div
      class="messageBody-centered-add-session-note-content"
      style="overflow: hidden"
    >
      <div class="add-form-img left">
        <img
          class="session-note-mod-img"
          src="{{ clientLogo }}"
          onerror="this.src='/assets/images/upload-pic-placeholder.png'"
          (click)="triggerUpload()"
        />
        <input
          type="file"
          id="clientLogoId"
          (change)="uploadFiles($event)"
          style="display: none"
        />
      </div>
      <div class="add-form left event-type-select"> 
        <mat-form-field appearance="fill" *ngIf="OperationClient == 2 || OperationClient == undefined" style="width: 250px; margin-left: 10px;background:#fff;color:#000!important;padding-bottom:4px">
          <mat-label>{{selectedclient ? '': 'Select Client'}}</mat-label>
          <mat-select [(ngModel)]="selectedclient" name="clients" (selectionChange)="clientChange($event)" >
            <mat-option *ngFor="let clients of clientList" [value]="clients.id">
              {{clients.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      <mat-radio-group aria-label="Select an option" (change)="operationChange($event)" class="OperationClient" name="OperationClient" ngDefaultControl [(ngModel)]="OperationClient">
        <mat-radio-button [value]="1">Create</mat-radio-button>
        <mat-radio-button [value]="2">Update</mat-radio-button>
      </mat-radio-group>
      </div>
      <div class="add-form left">
       
        <input
          matInput
          type="text"
          placeholder="Company Name"
          id="companyname"
          class="add-form-input font-27"
          (change)="checkAndUpdateIfExists()"
        />

      </div>
      <div class="add-form left">
        
        <input
          matInput
          type="text"
          placeholder="Company Website"
          id="companywebsite"
          class="add-form-input font-27"
        />
      </div>
      <span class="add-form-input-label" style="margin-left: 29px"
        >About Info</span
      >
      <div class="full-width">
        <!-- <textarea
          matInput
          type="textarea"
          id="aboutinfo"
          class="add-form-textarea font-27"
          rows="4"
          columns="50"
        ></textarea> -->
        <ckeditor
          #editor
          id="aboutinfo"
          [editor]="Editor"
          [formControl]="info"
        ></ckeditor>
        <br />
      </div>
      <div class="full-width">
        <button mat-button class="done-btn right"  style="width:120px" *ngIf="OperationClient == 1"    (click)="createClient()">CREATE</button>
        <button mat-button class="done-btn right" style="width:120px" *ngIf="OperationClient == 2" (click)="updateClient()">UPDATE</button>
        <button mat-button class="reset-btn right" (click)="reset()">
          RESET
        </button>
        <button mat-button class="done-btn right" (click)="done()">Done</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
