import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createModerator, postModerator } from "../constants/models";
import { API } from "../constants/urls";
import { SnackbarService } from "./snackbar.service";

@Injectable({
  providedIn: "root",
})
export class ModeratorService {
  constructor(private http: HttpClient, private snackBar: SnackbarService) {}

  addModerator(data: any) {
    return this.http.post<any>(API.ADD_MODERATOR, data, {
      // headers: {
      //   "Content-Type":
      //     "multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW",
      // },
    });
  }

  getModerators(params: any) {
    const qs = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    return this.http.get<any>(`${API.GET_MODERATOR}?${qs}`);
  }
}
