import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "../constants/urls";

@Injectable({
  providedIn: "root",
})
export class AdditionalLogoService {
  constructor(private http: HttpClient) {}

  createAdditionalLogo(data: any) {
    return this.http.post<any>(API.ADDITION_LOGO, data);
  }

  updateAdditionalLogo(data:any, Id:number){
    return this.http.patch<any>(API.ADDITION_LOGO+'/'+Id, data)
  }

  getAdditionalLogo(id: number) {
    return this.http.get<any>(API.GET_ADDITION_LOGO(id));
  }

  getAllAdditionalLogo(params:any) {
    return this.http.get<any>(API.GET_ADDITION_LOGOS(), params);
  }

  deleteAdditionalLogo(id: number) {
    return this.http.delete<any>(API.ADDITION_LOGO+'/'+id);
  }

   
}
