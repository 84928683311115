<div class="hide-page"></div>
<mat-dialog-content>
  <div class="messageBody-centered">
    <p class="static-warning-text">
      Ares you sure you want to delete the {{type}}
    </p>
    <p class="selected-booklet-name">{{ name }} ?</p>
    <div class="messageBody-button-container">
      <button
        (click)="eventCallNo()"
        class="warning-button-dialog"
        mat-raised-button
      >
        NO
      </button>
      <button
        (click)="eventCallYes()"
        class="success-button-dialog"
        mat-raised-button
      >
        YES
      </button>
    </div>
  </div>
</mat-dialog-content>
