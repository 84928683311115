import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/urls';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  /** post login service **/
  public loginUser(username: string, password: string) {
    const API_URL: string = API.USER_LOGIN;
    const data = {
      username: username,
      password: password,
    };
    return this.http.post<any>(API.USER_LOGIN, data);
  }
}
