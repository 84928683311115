import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SessionNotesService } from "src/app/services/session-notes.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ConfirmDeleteDialogComponent } from "../confirm-delete-dialog/confirm-delete-dialog.component";
import { FrozenConfirmationDialogComponent } from "../frozen-confirmation-dialog/frozen-confirmation-dialog.component";
import { EventTypeModel } from "../../constants/models";

@Component({
  selector: "app-session-notes",
  templateUrl: "./session-notes.component.html",
  styleUrls: ["./session-notes.component.css"],
})
export class SessionNotesComponent implements OnInit {
  serviceResponseProgress = true;
  srpColor = "primary";
  srpMode = "indeterminate";
  srpValue = 60;

  userId: string = "";
  sessionNotesResults: any;

  searchName = new FormControl("");

  calendarMonths = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getUTCFullYear();
  calendarYears = [
    this.currentYear - 1,
    this.currentYear,
    this.currentYear + 1,
    this.currentYear + 2,
    this.currentYear + 3,
  ];

  selectedYear = "YEAR";
  selectedMonthIndex = this.currentMonth;
  selectedMonth = this.calendarMonths[this.selectedMonthIndex].substring(0, 3);
  urlPart: string = "";
  isFrozen!: boolean;
 
  eventTypes: any = [];
  selectedEventType: any;
  // selectedEventType = {
  //   id: 0,
  //   name: "Type"
  // };

  constructor(
    private sessionNotesService: SessionNotesService,
    private dialog: MatDialog,
    private dialogD: MatDialog,
    private snackBar: SnackbarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem("bfs_buyerprofile_uid") || "";
    this.get_event_types();
    this.get_session_notes();
  }

  get_event_types(){
    this.sessionNotesService.get_event_types().subscribe((data) => {
      this.eventTypes = data.results;
    })
    
  }

  get_session_notes(urlPart: Object = {}) {
    this.route.queryParamMap.subscribe((params) => {
      this.isFrozen = JSON.parse(params.get("is_frozen") || "false");
      this.sessionNotesService
        .get_session_notes({ ...{ is_frozen: this.isFrozen }, ...urlPart })
        .subscribe((data) => {
          this.sessionNotesResults = data;
          this.serviceResponseProgress = false;
        });
    });
  }

  filterYear(selectedYearValue: any) {
    this.selectedYear = selectedYearValue;
    this.applySessionNotesFilter();
  }

  filterMonth(selectedMonthValue: string) {
    this.selectedMonth = selectedMonthValue.substring(0, 3);
    this.selectedMonthIndex = this.calendarMonths.findIndex(
      (x) => x === selectedMonthValue
    );
    this.applySessionNotesFilter();
  }

  filterEventType(item: any){
    this.selectedEventType = item;
    this.applySessionNotesFilter();
  }

  // applySessionNotesTypeFilter(){
  //   var selectedYearModified = ''
  //   var selectedMonthModified = ''

  //   if(this.selectedYear === 'YEAR') {
  //     selectedYearModified = ''
  //   }
  //   if(this.selectedMonth === 'MONTH') {
  //     selectedMonthModified = ''
  //   }
    
  //   this.route.queryParamMap.subscribe((params) => {
  //   const is_frozen = JSON.parse(params.get("is_frozen") || "false");
  //   var filters = {
  //     title: this.searchName.value,
  //     is_frozen: is_frozen,
  //     year: selectedYearModified,
  //     month: selectedMonthModified,
  //     event_type: this.selectedEventType.id
  //   };

  //   this.sessionNotesService
  //   .get_session_notes(filters)
  //   .subscribe((data) => {
  //     this.sessionNotesResults = data;
  //     this.serviceResponseProgress = false;
  //   });
  //   })
  // }

  // filterName() {
  //   if (
  //     this.searchName.value.length == 0 ||
  //     this.searchName.value.length >= 3
  //   ) {
  //     this.route.queryParamMap.subscribe((params) => {
  //       const is_frozen = JSON.parse(params.get("is_frozen") || "false");
  //       var filters = {
  //         title: this.searchName.value,
  //         is_frozen: is_frozen,
  //         year: "",
  //         month: "",
  //         event_type: this.selectedEventType.id
  //       };
  //       if (this.selectedYear != "YEAR") {
  //         filters.year = this.selectedYear;
  //         filters.month = String(this.selectedMonthIndex + 1);
  //       }
  //       this.sessionNotesService
  //         .get_session_notes(filters)
  //         .subscribe((data) => {
  //           this.sessionNotesResults = data;
  //           this.serviceResponseProgress = false;
  //         });
  //     });
  //   }
  // }

  applySessionNotesFilter() {

    this.serviceResponseProgress = true;
    this.route.queryParamMap.subscribe((params) => {
    const is_frozen = JSON.parse(params.get("is_frozen") || "false");
    var filters = {
              title: this.searchName.value,
              is_frozen: is_frozen,
              year: "",
              month: "",
              event_type: ""
    };
    if(this.selectedYear != "YEAR") {
      filters.year = this.selectedYear;
      filters.month = String(this.selectedMonthIndex + 1);
    }
    if(this.selectedEventType){filters.event_type = this.selectedEventType.id};

    console.log(filters)

    this.sessionNotesService.get_session_notes(filters).subscribe((data) => {
      this.sessionNotesResults = data;
      this.serviceResponseProgress = false;
    });
      
    })

    

    // this.serviceResponseProgress = true;
    // if (this.selectedYear === "YEAR") {
    //   this.snackBar.snackBarDanger("Please Select Event Year & Month");
    //   this.serviceResponseProgress = false;
    // } else {
    //   this.route.queryParamMap.subscribe((params) => {
    //     const is_frozen = JSON.parse(params.get("is_frozen") || "false");
    //     this.sessionNotesService
    //       .get_session_notes({
    //         year: this.selectedYear,
    //         month: String(this.selectedMonthIndex + 1),
    //         title: this.searchName.value,
    //         is_frozen: is_frozen,
    //         event_type: this.selectedEventType.id
    //       })
    //       .subscribe((data) => {
    //         this.sessionNotesResults = data;
    //         this.serviceResponseProgress = false;
    //       });
    //   });
    // }
  }

  navigatePage(url: string) {
    this.serviceResponseProgress = true;
    this.urlPart = url.split("?")[1];
    const qpObj = this.getURLParametersObject(this.urlPart);
    this.get_session_notes(qpObj);
  }

  freezeDialog(id: number, name: string, action: string) {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "50%";
    dialogconfig.height = "50%";
    dialogconfig.data = {
      id: id,
      name: name,
      action: action,
    };
    const freezeBookletRef = this.dialog.open(
      FrozenConfirmationDialogComponent,
      dialogconfig
    );
    freezeBookletRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.snackBarSuccess(result);
        this.get_session_notes();
      }
    });
  }

  getURLParametersObject(qp: string) {
    return JSON.parse(
      '{"' + qp.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  }

  confirmDelete(id: number, name: string) {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "50%";
    dialogconfig.height = "50%";
    dialogconfig.data = {
      id: id,
      name: name,
    };
    const confDelete = this.dialogD.open(
      ConfirmDeleteDialogComponent,
      dialogconfig
    );
    confDelete.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.snackBarSuccess(result);
        this.get_session_notes();
      }
    });
  }

  resetFilters() {
    this.selectedYear = "YEAR";
    this.searchName = new FormControl("");
    this.selectedEventType = undefined;
    this.applySessionNotesFilter();
  }
}
