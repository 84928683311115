import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ParticipantService } from "src/app/services/participant.service";
import { SessionNotesService } from "src/app/services/session-notes.service";

@Component({
  selector: "app-confirm-delete-dialog",
  templateUrl: "./confirm-delete-dialog.component.html",
  styleUrls: ["./confirm-delete-dialog.component.css"],
})
export class ConfirmDeleteDialogComponent implements OnInit {
  id: number;
  name: string;
  serviceResponseProgress = false;
  type: string = "Session Note";

  constructor(
    private sessionNotesService: SessionNotesService,
    private participantService: ParticipantService,
    private dialogref: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
  }

  ngOnInit(): void {}

  eventCallYes() {
    this.serviceResponseProgress = true;
    var message = `${this.type} Deleted Successfully`;
    if (this.type == "Participant Summary") {
      this.participantService.deleteParticpant(this.id).subscribe((result) => {
        this.serviceResponseProgress = false;
        this.dialogref.close(message);
      });
    } else {
      this.sessionNotesService
        .delete_session_note(this.id)
        .subscribe((result) => {
          this.serviceResponseProgress = false;
          this.dialogref.close(message);
        });
    }
  }

  eventCallNo() {
    this.dialogref.close();
  }
}
