import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { API } from "src/app/constants/urls";

@Component({
  selector: "app-session-notes-preview",
  templateUrl: "./session-notes-preview.component.html",
  styleUrls: ["./session-notes-preview.component.css"],
})
export class SessionNotesPreviewComponent implements OnInit {
  id!: string;
  pdfSrc!: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id") || "1";
      this.pdfSrc = API.PREVIEW_NOTES(+this.id);
    });
  }

  refresh() {
    window.location.reload();
  }
}
