import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-title-para',
  templateUrl: './add-title-para.component.html',
  styleUrls: ['./add-title-para.component.css']
})
export class AddTitleParaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
