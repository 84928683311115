import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { API } from "../constants/urls";

@Injectable({
  providedIn: "root",
})
export class SessionNotesService {
  constructor(private http: HttpClient) {}

  get_session_notes(params: any = {}) {
    return this.http.get<any>(API.GET_SESSION_NOTES, { params });
  }

  get_session_note(id: number) {
    return this.http.get<any>(API.GET_SESSION_NOTE(id));
  }

  get_event_types(){
    return this.http.get<any>(API.GET_EVENT_TYPES);
  }

  freeze_restore_session_notes(id: number, isFrozen: boolean) {
    return this.http.patch<any>(API.FREEZE_RESTORE_SESSION_NOTES(id), {
      is_frozen: isFrozen,
    });
  }

  create_session_note(data: any) {
    return this.http.post<any>(API.CREATE_SESSION_NOTE, data);
  }

  update_sesion_note(id: number, data: any) {
    return this.http.patch<any>(API.UPDATE_SESSION_NOTE(id), data);
  }

  get_sections(noteId: number) {
    return this.http.get<any>(API.GET_SECTION_NOTE(noteId));
  }

  delete_session_note(id: number) {
    return this.http.delete<any>(API.DELETE_SECTION_NOTE(id));
  }
}
