import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { postModerator, RemovedModerators } from "src/app/constants/models";
import { ModeratorService } from "src/app/services/moderator.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { createModerator } from "src/app/constants/models";

@Component({
  selector: "app-add-moderator",
  templateUrl: "./add-moderator.component.html",
  styleUrls: ["./add-moderator.component.css"],
})
export class AddModeratorComponent implements OnInit {
  moderators: any = [];
  postData: postModerator[] = [];

  // for image upload
  uploadForm: FormGroup = new FormGroup({});
  imageSrc: string[] = ["", "", "", "", "", "", "", "", "", "", ""];
  removedModerators: RemovedModerators[] = [];

  constructor(
    private dialogref: MatDialogRef<AddModeratorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: SnackbarService,
    private moderatorService: ModeratorService,
    private formBuilder: FormBuilder
  ) {
    if (data.moderators.length > 0) this.moderators = data.moderators;
    else this.moderators = [{ id: undefined }];

    console.log(this.moderators )
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      image1: [""],
      image2: [""],
      image3: [""],
      image4: [""],
      image5: [""],
      image6: [""],
      image7: [""],
      image8: [""],
      image9: [""],
      image10: [""],
    });
    this.moderators.forEach(( e:any, i:number) =>{
      this.uploadForm.get(`image${i+1}`)?.setValue(e.image)
    })
  }

  closeDialog() {
    const result = { saved: false };
    this.dialogref.close(result);
  }

  removeModerator() {
    if (this.moderators.length == 0) {
      this.snackBar.snackBarWarning("Moderators does not exists");
      return false;
    }
    if (this.moderators.length == 1) {
      this.snackBar.snackBarWarning("Need to have atleast one moderator");
      return false;
    }

    this.removedModerators.push({
      id: this.moderators[this.moderators.length - 1].id,
    });
    this.moderators.pop();
    this.snackBar.snackBarSuccess("Moderator removed successfully");
    return true;
  }

  triggerUpload(no: number) {
    const upload = document.getElementById(
      `moderatorImage_${no}`
    ) as HTMLInputElement;
    upload.click();
  }

  uploadFiles(event: any, no: number) {
    console.log(event,no,`image${no}`, event.target.files[0])
    const file = event.target.files[0];
    const whichImage = `image${no}`;
    this.uploadForm.get(whichImage)?.setValue(file);
    this.uploadForm.patchValue({
      logo: file, 
    });

    this.uploadForm.get(whichImage)?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = (e) => {
      this.moderators[no - 1].image = e.target?.result;
      this.imageSrc[no - 1] = `${e.target?.result}`;
    };
    reader.readAsDataURL(file);
  }

  reset() {
    this.moderators.forEach((element: number) => {
      var x = document.getElementById(
        `sessionnotename_${element}`
      ) as HTMLInputElement;
      var y = document.getElementById(
        `sessionnotedesignation_${element}`
      ) as HTMLInputElement;
      x.value = "";
      y.value = "";
    });
  }

  done() {
    let allowAdd = true;
    var i = 0;
    this.moderators.forEach((element: any) => {
      i += 1;
      var name = (
        document.getElementById(`sessionnotename_${i}`) as HTMLInputElement
      ).value;
      var designation = (
        document.getElementById(
          `sessionnotedesignation_${i}`
        ) as HTMLInputElement
      ).value;
      var modId = (document.getElementById(`modId_${i}`) as HTMLInputElement)
        .value;
      var linkedInUrl = (
        document.getElementById(`linkedin_url_${i}`) as HTMLInputElement
      ).value;
      var ModImage = this.uploadForm.get(`image${i}`)?.value;
      if (!name || !designation || !linkedInUrl || ModImage == '' || ModImage == undefined ||  ModImage == null) {
        this.snackBar.snackBarDanger(
          "Cannot save moderators. Please make sure all moderators have Name, Image, Linkedin Url & Designation added."
        );
        allowAdd = false;
        return;
      } else {
        console.log(this.postData);
        this.postData.push({
          name: name,
          designation: designation,
          id: +modId,
          linkedin_url: linkedInUrl,
        });
      }
    });

    // add moderator to db
    const formData = new FormData();

    for (var i = 0; i <= this.moderators.length; i++) {
      if (this.imageSrc[i])
        formData.append(
          `image${i + 1}`,
          this.uploadForm.get(`image${i + 1}`)?.value
        );
    }
    formData.append(
      "data",
      JSON.stringify({
        moderators: this.postData,
        removedModerators: this.removedModerators,
      })
    );
    if (allowAdd) {
      this.moderatorService.addModerator(formData).subscribe((result) => {
        if (result.status === true) {
          this.snackBar.snackBarSuccess(result.message);
        } else {
          this.snackBar.snackBarDanger(result.message);
        }
        this.dialogref.close(result.ids);
      });
    }
  }

  addMore() {
    let allowAdd = true;
    var i = 0;
    const modLength = this.moderators.length;
    this.moderators.forEach((element: any) => {
      i += 1;
      if (
        !(document.getElementById(`sessionnotename_${i}`) as HTMLInputElement)
          .value ||
        !(
          document.getElementById(
            `sessionnotedesignation_${i}`
          ) as HTMLInputElement
        ).value
      ) {
        this.snackBar.snackBarDanger("Add One moderator first");
        allowAdd = false;
        return;
      }
    });
    if (this.moderators.length == 10) {
      this.snackBar.snackBarDanger("You may add only upto 10 Moderators");
    } else if (allowAdd) {
      var newId = modLength + 1;
      this.moderators.push({
        name: "",
        designation: "",
        image: "",
      });
    }
  }
}
