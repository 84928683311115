import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import {
  aboutAdditionalLogo,
  aboutClient,
  createModerator,
  EventTypeModel,
  RemovedSections,
  Sections,
  sessionNote,
} from "src/app/constants/models";
import { ClientInfoService } from "src/app/services/client-info.service";
import { ModeratorService } from "src/app/services/moderator.service";
import { SessionNotesService } from "src/app/services/session-notes.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AboutClientComponent } from "../about-client/about-client.component";
import { AddModeratorComponent } from "../add-moderator/add-moderator.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AdditionalLogoComponent } from "../additional-logo/additional-logo.component";
import { AdditionalLogoService } from "src/app/services/additional-logo.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import { DeleteConfirmComponent } from "../delete-confirm/delete-confirm.component";

@Component({
  selector: "app-add-session-note",
  templateUrl: "./add-session-note.component.html",
  styleUrls: ["./add-session-note.component.css"],
})
export class AddSessionNoteComponent implements OnInit {

  // initializing 20 sections
  // myGroup = new FormGroup({
  //   sectiontitle_1: new FormControl(""),
  //   sectionparagraph_1: new FormControl(""),
  //   sectiontitle_2: new FormControl(""),
  //   sectionparagraph_2: new FormControl(""),
  //   sectiontitle_3: new FormControl(""),
  //   sectionparagraph_3: new FormControl(""),
  //   sectiontitle_4: new FormControl(""),
  //   sectionparagraph_4: new FormControl(""),
  //   sectiontitle_5: new FormControl(""),
  //   sectionparagraph_5: new FormControl(""),
  //   sectiontitle_6: new FormControl(""),
  //   sectionparagraph_6: new FormControl(""),
  //   sectiontitle_7: new FormControl(""),
  //   sectionparagraph_7: new FormControl(""),
  //   sectiontitle_8: new FormControl(""),
  //   sectionparagraph_8: new FormControl(""),
  //   sectiontitle_9: new FormControl(""),
  //   sectionparagraph_9: new FormControl(""),
  //   sectiontitle_10: new FormControl(""),
  //   sectionparagraph_10: new FormControl(""),
  //   sectiontitle_11: new FormControl(""),
  //   sectionparagraph_11: new FormControl(""),
  //   sectiontitle_12: new FormControl(""),
  //   sectionparagraph_12: new FormControl(""),
  //   sectiontitle_13: new FormControl(""),
  //   sectionparagraph_13: new FormControl(""),
  //   sectiontitle_14: new FormControl(""),
  //   sectionparagraph_14: new FormControl(""),
  //   sectiontitle_15: new FormControl(""),
  //   sectionparagraph_15: new FormControl(""),
  //   sectiontitle_16: new FormControl(""),
  //   sectionparagraph_16: new FormControl(""),
  //   sectiontitle_17: new FormControl(""),
  //   sectionparagraph_17: new FormControl(""),
  //   sectiontitle_18: new FormControl(""),
  //   sectionparagraph_18: new FormControl(""),
  //   sectiontitle_19: new FormControl(""),
  //   sectionparagraph_19: new FormControl(""),
  //   sectiontitle_20: new FormControl(""),
  //   sectionparagraph_20: new FormControl(""),
  // });

 

 
  
  title = new FormControl("");
  datetime = new FormControl("");
  intro = new FormControl("");
  sessionNoteId!: string;
  moderators: createModerator[] = [];
  moderatorIds: number[] = new Array();
  sessionNote!: sessionNote;
  client!: aboutClient;
  additionalLogo: aboutAdditionalLogo[] = [];
  sections: Sections[] = [];
  sectionsPost: Sections[] = [];
  public formData = new FormData();
  uploadForm: FormGroup = new FormGroup({});
  imageSrc: string | null | undefined = null;
  removedSections: RemovedSections[] = [];
  pipe = new DatePipe("en-US");
  public Editor = ClassicEditor;
  eventTypes: EventTypeModel[] = [];
  selectedEventType = new FormControl();
  // selectedEventType = {
  //   id: 0,
  //   name: "Select Type"
  // };
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any> | undefined ;

  myGroup = this.formBuilder.group({
    section: this.formBuilder.array([])
  })

 
  constructor(
    private sessionNoteService: SessionNotesService,
    private moderatorService: ModeratorService,
    private dialog: MatDialog,
    private snackBar: SnackbarService,
    private route: ActivatedRoute,
    private aboutClient: ClientInfoService,
    private additionalLogos: AdditionalLogoService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.get_event_types();
   
  }

  get sectionFormGroup () {
    return (this.myGroup.get('section') as FormArray).controls
  }

   sectionItemGroup (index:number) {
    return this.sectionFormGroup[index] as FormGroup
  }

  

   sectionsControl(formVal:any){
    return this.formBuilder.group({
      sectiontitle: new FormControl(formVal[0]),
      sectionparagraph: new FormControl(formVal[1]),
    })
  }

  addSectionControl(formVal:any){
    const control = <FormArray>this.myGroup.controls['section']
    control.push(this.sectionsControl(formVal))
  }

  removeSectionControl(i: number) {
    const control = <FormArray>this.myGroup.controls['section'];
    control.removeAt(i);
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      logo: [""],
    });

   

    // check if edit or add mode
    this.route.paramMap.subscribe((params) => {
      this.sessionNoteId = params.get("id") || "add";
    });

    // if edit, get session note, moderators, client & sections
    if (this.sessionNoteId != "add") {
      this.sessionNoteService
        .get_session_note(+this.sessionNoteId)
        .subscribe((result: any) => {
          if (result) {
            this.title = new FormControl(result.title);
            this.intro = new FormControl(result.intro);
            this.selectedEventType = result.event_type;
            var scheduled_datetime = document.getElementById(
              "scheduled_datetime"
            ) as HTMLInputElement;
            scheduled_datetime.value =
              this.pipe.transform(
                result.session_scheduled_at,
                "M/dd/yyyy h:mm a"
              ) || result.session_scheduled_at;
            this.sessionNote = result;
            this.imageSrc = result.logo;
            if (result.client?.id) {
              this.aboutClient
                .getClient(result.client.id)
                .subscribe((client) => {
                  this.client = client.results[0];
                });
            };
            if (result.additional_logo_list) {
              this.additionalLogo = result.additional_logo_list
              // this.additionalLogos
              //   .getAdditionalLogo(result.client.id)
              //   .subscribe((logo) => {
              //     this.additionalLogo = logo.results[0];
              //   });
            }
          }
        });

      // get moderators
      this.moderatorService
        .getModerators({ note_id: this.sessionNoteId })
        .subscribe((data: any) => {
          this.moderators = data.results;
        });

      // get sections
      this.sessionNoteService
        .get_sections(+this.sessionNoteId)
        .subscribe((sections) => {
          this.sections = sections.results;
          if(this.sections){
            for (var i = 1; i <= sections.results.length; i++) {
              // this.myGroup
              //   .get(`sectiontitle_${i}`)
              //   ?.setValue(sections.results[i - 1]["title"]);
              // this.myGroup
              //   .get(`sectionparagraph_${i}`)
              //   ?.setValue(sections.results[i - 1]["paragraph"]);
              this.addSectionControl([sections.results[i - 1]["title"],sections.results[i - 1]["paragraph"]])
            }
          }
         
        });
    }
  }


  get_event_types(){
    this.sessionNoteService.get_event_types().subscribe((data) => {
      this.eventTypes = data.results;
    })
    
  }

  triggerUpload() {
    const upload = document.getElementById("uploadImage") as HTMLInputElement;
    upload.click();
  }

  uploadFiles(event: any) {
    const file = event.target.files[0];
    this.uploadForm.get("logo")?.setValue(file);
    this.uploadForm.patchValue({
      logo: file,
    });

    this.uploadForm.get("logo")?.updateValueAndValidity();

    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSrc = `${e.target?.result}`;
    };
    reader.readAsDataURL(file);
  }

  createNote() {
    if (this.sessionNoteId == "add") {
      this.sessionNoteService
        .create_session_note({
          title: this.title.value,
        })
        .subscribe(
          (result) => {
            if (result && result?.id !== null) {
              this.sessionNoteId = result.id;
            } else {
              this.snackBar.snackBarDanger(
                "Cannot create Session Note. Please contact admin"
              );
            }
          },
          (error) => {
            if (error.status == 400) {
              this.snackBar.snackBarDanger(
                "Session Notes with this title already exists, Please select a unique title"
              );
              this.title = new FormControl("");
            }
          }
        );
    }
  }

  saveSessionNote() {
    this.sessionNoteService
      .update_sesion_note(+this.sessionNoteId, {})
      .subscribe((result) => {});
  }

  addModerator() {
    if (this.validate() === false) {
      return;
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "50%";
    dialogconfig.height = "50%";
    dialogconfig.data = {
      moderators: this.moderators,
    };
    const addSessionNotRef = this.dialog.open(
      AddModeratorComponent,
      dialogconfig
    );
    addSessionNotRef.afterClosed().subscribe((result) => {
      if (result?.saved == false) {
        // nothing doing
      } else if (result?.length > 0) {
        this.sessionNoteService
          .update_sesion_note(+this.sessionNoteId, { moderator: result })
          .subscribe((response) => {
            this.moderatorService
              .getModerators({ note_id: this.sessionNoteId })
              .subscribe((data: any) => {
                this.moderators = data.results;
              });
          });
      }
    });
  }

  openDialog(item:any): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '350px',
      height:'222px',
      data: {name: item.url,}
    });

    dialogRef.afterClosed().subscribe(result => {
      result != undefined && this.deleteAdditionalLogo(item.id)
    });
  }
 

  deleteAdditionalLogo(id:number){
    this.additionalLogos.deleteAdditionalLogo(id).subscribe((result: any) =>{
       this.snackBar.snackBarSuccess("Additional Logo deleted.");
       this.additionalLogo = this.additionalLogo.filter(e => e.id != id) 
    })
  }
  addAdditionalLogo() {
    if (this.validate() === false) {
      return;
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "500px";
    dialogconfig.height = "200px";
    dialogconfig.data = {
    };
    const addSessionNotRef = this.dialog.open(
      AdditionalLogoComponent,
      dialogconfig
    );
    addSessionNotRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result)
       this.additionalLogo.push(result)
          // this.snackBar.snackBarSuccess("Client saved successfully.");
      }
    });
  }

  openAdditionalLogo(item:any) {
    if (this.validate() === false) {
      return;
    }
    console.log(item)
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "600px";
    dialogconfig.height = "300px";
    dialogconfig.data = {
      additionalLogo: item,
    };
    const addSessionNotRef = this.dialog.open(
      AdditionalLogoComponent,
      dialogconfig
    );
    addSessionNotRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result)
       this.additionalLogo = result
          // this.snackBar.snackBarSuccess("Client saved successfully.");
      }
    });
  }
  addClient() {
    if (this.validate() === false) {
      return;
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "50%";
    dialogconfig.height = "50%";
    dialogconfig.data = {
      client: this.client,
    };
    const addSessionNotRef = this.dialog.open(
      AboutClientComponent,
      dialogconfig
    );
    addSessionNotRef.afterClosed().subscribe((result) => {
      if (result?.id) {
        this.client = result;
        if (this.client.saved == true)
          this.snackBar.snackBarSuccess("Client saved successfully.");
          
      }
    });
  }
 
  addSection() {  
    if (this.validate() === false) {
      return;
    }

    let valid = true;
    const sections = this.sections.length;
    // if (sections > 0) {
      // var lastSectionTitle = this.myGroup.value[`sectiontitle_${sections}`];
      // var lastSectionPara = this.myGroup.value[`sectionparagraph_${sections}`];
      // var lastSectionPara = this.sectionFormGroup[sections].value.sectionparagraph;
      // this.addSectionControl(['',''])
      // if (!lastSectionPara) {
      //   valid = false;
      //   this.snackBar.snackBarDanger(
      //     "Please add one item."
      //   );
      // }
    // }
    if (valid ) {
      var panel = document.getElementById("section-panel") as HTMLInputElement;
      panel.style.display = "block";
      var new_id = sections + 1;
      this.addSectionControl(['','']);
      this.sections.push({
        id: new_id,
        title: "",
        paragraph: "",
        tmp: true,
      });
    }
 
    // if (sections > 0) {
    //   var lastSectionTitle = document.getElementById(
    //     `sectiontitle_${sections}`
    //   ) as HTMLInputElement;
    //   var lastSectionPara = document.getElementById(
    //     `sectionparagraph_${sections}`
    //   ) as HTMLInputElement;

    //   if (!lastSectionPara.value) {
    //     valid = false;
    //     this.snackBar.snackBarDanger("Please add one section first.");
    //   }
    // }
    // if (valid) {
    //   var panel = document.getElementById("section-panel") as HTMLInputElement;
    //   panel.style.display = "block";
    //   var new_id = sections + 1;
    //   this.sections.push({
    //     id: new_id,
    //     title: "",
    //     paragraph: "",
    //     tmp: true,
    //   });
    // }
  }

  removeSection() {
    if (this.sections.length == 0) {
      this.snackBar.snackBarWarning("Section does not exists");
      return false;
    }
   
    this.removedSections.push({
      id: this.sections[this.sections.length - 1].id,
    });
    this.sections.pop();
    // console.log(this.sections , this.removedSections);
    this.removeSectionControl(this.sections.length - 1)
    this.snackBar.snackBarSuccess("Section removed successfully");
    return true;
  }

  // save the session note
  save(isPreview: boolean = false) {
    if (this.validateSave(isPreview) === false) {
      return false;
    }

    this.moderators.forEach((element) => {
      if (element?.id) this.moderatorIds.push(element?.id);
    });

    let i = 0;
    var rowSection;
    this.sectionsPost = [];
    this.sections.forEach((eachSection) => {
      i += 1;
      // var title = (
      //   document.getElementById(`sectiontitle_${i}`) as HTMLInputElement
      // ).value.trim();
      // var paragraph = (
      //   document.getElementById(`sectionparagraph_${i}`) as HTMLInputElement
      // ).value.trim();
      // var title = this.myGroup.value[`sectiontitle_${i}`].trim();
      // var paragraph = this.myGroup.value[`sectionparagraph_${i}`].trim();
      // console.log(i,this.sections,this.sectionFormGroup[i-1],this.sectionFormGroup[i-1].value.sectiontitle,this.sectionFormGroup[i-1].value.sectionparagraph) 
      var title = this.sectionFormGroup[i-1].value.sectiontitle
      var paragraph = this.sectionFormGroup[i-1].value.sectionparagraph
      // console.log(title, paragraph)
      if (paragraph !== "" && paragraph !== undefined) {
        if (eachSection.tmp === true) {
          rowSection = { title: title, paragraph: paragraph };
        } else {
          rowSection = {
            title: title,
            paragraph: paragraph,
            id: eachSection.id,
          };
        }
        
        this.sectionsPost.push(rowSection);
        // console.log(this.sectionsPost )
        this.sectionsPost = Array.from(new Set(this.sectionsPost)) 
        // console.log(this.sectionsPost )
      }
    });

    var scheduled_datetime = document.getElementById(
      "scheduled_datetime"
    ) as HTMLInputElement;
      
    let postData = {
      event_type: this.selectedEventType,
      title: this.title.value,
      session_scheduled_at: this.pipe.transform(
        scheduled_datetime.value,
        "yyyy-MM-dd HH:mm:ss"
      ),
      intro: this.intro.value,
      is_frozen: false,
      is_saved: true,
      client: this.client?.id,
      moderator: this.moderatorIds,
      sections: this.sectionsPost,
      removedSections: this.removedSections,
      additional_logo:this.additionalLogo.map(e => e.id)
    };
    // console.log(postData, this.selectedEventType)

    const formData = new FormData();
    formData.append("file", this.uploadForm.get("logo")?.value);
    formData.append("data", JSON.stringify(postData));
    // console.log(+this.sessionNoteId)
    // TODO fill client, moerator & sections
    this.sessionNoteService
      .update_sesion_note(+this.sessionNoteId, formData)
      .subscribe((result) => {
        if (result.id) {
          if (!isPreview) {
            this.snackBar.snackBarSuccess("Session Notes saved successfully");
            setTimeout(() => {
              window.location.reload();
            }, 200);
            // if (this.sessionNoteId == "add"){
            //   this.router.navigate(['/session-notes/', { id: result.id }]  );
            // }
          }
        } else {
          this.snackBar.snackBarDanger("Error saving Session note");
        }
      });
    return true;
  }

  // updateEventType() {
  //   console.log(this.selectedEventType)
  // }

  preview(isPreview: boolean = false) {
    const isSaved = this.save(isPreview);
    if (isSaved) {
      window.open(`/session-notes/preview/${this.sessionNoteId}`, "_blank");
    }
    // window.open(`/session-notes/preview/${this.sessionNoteId}`, "_blank");
  }

 

  validate() {
    if (this.title.value == "") {
      this.snackBar.snackBarDanger(
        "Please add the Title of the Session Note first"
      );
      return false;
    }
    return true;
  }

  validateSave(isPreview: boolean) {
    var missingMandatoryFields = [];
    if (this.title.value == "" || this.title.value == null)
      missingMandatoryFields.push("Title");
    if (this.intro.value == "" || this.intro.value == null)
      missingMandatoryFields.push("Intro");
    if (this.imageSrc == "" || this.imageSrc == null)
      missingMandatoryFields.push("Session Note logo");
    if (this.moderators.length == 0) missingMandatoryFields.push("Moderator");
    if (this.sections.length == 0) missingMandatoryFields.push("Section");
    if (this.client?.id == null) missingMandatoryFields.push("Client");
    if (Number(this.selectedEventType) == 0) missingMandatoryFields.push("Event type");

    const mandatoryFields = missingMandatoryFields.join(", ");

    if (missingMandatoryFields.length > 0) {
      if (!isPreview) {
        this.snackBar.snackBarDanger(
          `Cannot save Session Note, please fill in the Mandatory fields: ${mandatoryFields}`
        );
      } else {
        this.snackBar.snackBarDanger(
          `Cannot Preview, please fill in the Mandatory fields: ${mandatoryFields}`
        );
      }
      return false;
    }
    return true;
  }

  onReady() {}
}
