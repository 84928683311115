import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {MatRadioModule} from '@angular/material/radio';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { FormsModule ,ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LoaderService } from "./services/loader.service";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
import { MatIconModule } from "@angular/material/icon";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SessionNotesComponent } from "./components/session-notes/session-notes.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SessionNotesPreviewComponent } from "./components/session-notes-preview/session-notes-preview.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { MatDialogModule } from "@angular/material/dialog";
import { FrozenConfirmationDialogComponent } from "./components/frozen-confirmation-dialog/frozen-confirmation-dialog.component";
import { AddSessionNoteComponent } from "./components/add-session-note/add-session-note.component";
import { AddModeratorComponent } from "./components/add-moderator/add-moderator.component";
import { AddTitleParaComponent } from "./components/add-title-para/add-title-para.component";
import { AboutClientComponent } from "./components/about-client/about-client.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ToastrModule } from "ngx-toastr";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ConfirmDeleteDialogComponent } from "./components/confirm-delete-dialog/confirm-delete-dialog.component";
import { ChartsModule } from "ng2-charts";
import { AgGridModule } from "ag-grid-angular";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ParticipantSummaryComponent } from './components/participant-summary/participant-summary.component';
import { AddParticipantSummaryComponent } from './components/add-participant-summary/add-participant-summary.component';
import { ParticipantSummaryPreviewComponent } from './components/participant-summary-preview/participant-summary-preview.component';
import {MatSelectModule} from '@angular/material/select';
import { AdditionalLogoComponent } from './components/additional-logo/additional-logo.component';
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component';
 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SessionNotesComponent,
    SidenavComponent,
    SessionNotesPreviewComponent,
    FrozenConfirmationDialogComponent,
    AddSessionNoteComponent,
    AddModeratorComponent,
    AddTitleParaComponent,
    AboutClientComponent,
    StatisticsComponent,
    ConfirmDeleteDialogComponent,
    ParticipantSummaryComponent,
    AddParticipantSummaryComponent,
    ParticipantSummaryPreviewComponent,
    AdditionalLogoComponent,
    DeleteConfirmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSidenavModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxExtendedPdfViewerModule,
    MatDialogModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CKEditorModule,
    ChartsModule,
    AgGridModule.withComponents([]),
    MatAutocompleteModule,
    MatRadioModule
  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [FrozenConfirmationDialogComponent],
})
export class AppModule {}
