import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { API } from "src/app/constants/urls";
import { ParticipantService } from "src/app/services/participant.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ConfirmDeleteDialogComponent } from "../confirm-delete-dialog/confirm-delete-dialog.component";

@Component({
  selector: "app-participant-summary",
  templateUrl: "./participant-summary.component.html",
  styleUrls: ["./participant-summary.component.css"],
})
export class ParticipantSummaryComponent implements OnInit {
  userId: string = "";
  urlPart: string = "";
  serviceResponseProgress = true;
  participantResults: any;
  searchName = new FormControl("");

  srpColor = "primary";
  srpMode = "indeterminate";
  srpValue = 60;

  constructor(
    private participantService: ParticipantService,
    private dialogD: MatDialog,
    private snackBar: SnackbarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem("bfs_buyerprofile_uid") || "";
    this.getNotesWithParticipant();
  }

  getNotesWithParticipant(urlPart: Object = {}) {
    this.participantService.getParticipants(urlPart).subscribe((data: any) => {
      this.participantResults = data;
      this.serviceResponseProgress = false;
    });
  }

  searchparticipantSummary() {
    if (
      this.searchName.value.length == 0 ||
      this.searchName.value.length >= 3
    ) {
      this.route.queryParamMap.subscribe((params) => {
        var filters = {
          title: this.searchName.value,
        };
        this.participantService
          .getParticipants(filters)
          .subscribe((data: any) => {
            this.participantResults = data;
            this.serviceResponseProgress = false;
          });
      });
    }
  }

  resetFilters() {}

  confirmDelete(id: number, title: string) {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.width = "50%";
    dialogconfig.height = "50%";
    dialogconfig.data = {
      id: id,
      name: title,
      type: "Participant Summary",
    };
    const confDelete = this.dialogD.open(
      ConfirmDeleteDialogComponent,
      dialogconfig
    );
    confDelete.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.snackBarSuccess(result);
        this.getNotesWithParticipant();
      }
    });
  }

  navigatePage(url: string) {
    this.serviceResponseProgress = true;
    this.urlPart = url.split("?")[1];
    const qpObj = this.getURLParametersObject(this.urlPart);
    this.getNotesWithParticipant(qpObj);
  }

  getURLParametersObject(qp: string) {
    return JSON.parse(
      '{"' + qp.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  }

  filter() {}

  download(id: number) {
    window.open(
      `${API.BACKEND_URL}notes/participant/${id}/download`,
      "_blank"
    );
  }
}
