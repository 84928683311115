<mat-sidenav-container class="main-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="main-sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <div class="main-sidenav-content">
    <h1>Add/Edit Session Note</h1>
    <div class="mat-tab-container">
      <div class="mat-tab-content-details">
        <div class="full-width add-session-note-section left">
          <h2>
            <p class="red-text rubik capital">Fill the form</p>
          </h2>
          <img id="session-note-img" class="session-note-img" src="{{ imageSrc }}" (click)="triggerUpload()"
            onerror="this.src='/assets/images/upload-pic-placeholder.png'" />





          <!-- <span
        class="button-seperator"
     
      >
        <mat-button-toggle-group
          [matMenuTriggerFor]="dropdownMenuEventType"
          class="header-height"
        >
          <mat-button-toggle class="split-button select-type" style="padding: 4px 0px">
            {{selectedEventType.name}}
          </mat-button-toggle>
          <mat-button-toggle class="split-button">

            <img
              src="assets/images/down-arrow-select.png"
              class="mat-drop-down-icon"
            />
          </mat-button-toggle>
        </mat-button-toggle-group>

        
        
        <mat-menu #dropdownMenuEventType="matMenu">
          <button
            *ngFor="let event of eventTypes"
            
            mat-menu-item
          >
            {{ event.name }}
          </button>
        </mat-menu>
      </span> -->
          <!-- (click)="filterEventType(event)" -->

          <input type="file" id="uploadImage" (change)="uploadFiles($event)" style="display: none" />
          <div class="add-form">


            <div style="padding-bottom: 20px;" class="event-type-select">
              <span class="add-form-input-label rubik">Type:</span>
              <mat-form-field appearance="fill" style="width: 250px; margin-left: 30px;">

                <mat-label>{{selectedEventType ? '': 'Selected Event type'}}</mat-label>

                <!-- <mat-label *ngIf="selectedEventType.id > 0">{{selectedEventType.name}}</mat-label> -->
                <!-- <mat-label *ngIf="selectedEventType.id === 0">Select Type</mat-label> -->
                <mat-select [(ngModel)]="selectedEventType" name="eventtype">
                  <mat-option *ngFor="let event of eventTypes" [value]="event.id">
                    {{event.name}}
                  </mat-option>
                </mat-select>
                <!-- <mat-label>{{(selectedEventType?.id == 0) ? selectedEventType?.name : 'Select Type'}}</mat-label> -->
              </mat-form-field>
            </div>


            <input matInput type="text" placeholder="Topic / Title" [formControl]="title"
              class="add-form-input font-21 rubik" (blur)="createNote()" />
            <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" class="add-form-input font-21 rubik"
              placeholder="Scheduled Date Time" id="scheduled_datetime" hour12Timer="false" />
            <owl-date-time #dt1></owl-date-time>
            <span class="add-form-input-label rubik">Intro</span>
            <!-- <textarea
              matInput
              rows="4"
              class="add-form-textarea font-27 rubik"
            ></textarea> -->
            <ckeditor #editor id="editor" [editor]="Editor" [formControl]="intro"></ckeditor>
          </div>
        </div>
        <!-- Add moderator or List Moderator -->
        <div *ngIf="moderators.length == 0" class="left full-width">
          <div>
            <button _ngcontent-dxo-c17="" class="action-button mat-raised-button mat-button-base" mat-raised-button=""
              style="width: 300px">
              <span class="mat-button-wrapper" (click)="addModerator()">
                Add Moderator*
                <img _ngcontent-dxo-c17="" required src="assets/images/add-new.png" />
                <input _ngcontent-dxo-c17=""
                  style="display: none" type="file" /></span>
              <div class="mat-button-ripple mat-ripple" matripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </button><br /><br />
          </div>
        </div>
        <div *ngIf="moderators.length > 0" class="left full-width pointer" (click)="addModerator()">
          <span class="head-text montserrat full-width left">Moderators</span>
          <hr />
          <br />
          <span class="img-info" *ngFor="let person of moderators">
            <div class="each-moderator rubik">
              <img src="{{ person.image }}"  />
              <span class="designation head-text">
                <b> {{ person.name }} </b><br /><br /></span>
              <span class="designation rubik">{{ person.designation }}</span>
            </div>
          </span>
        </div>
        <!-- Add/List title para -->
        <div class="left full-width" *ngIf="sections.length >= 0">
          <span class="head-text montserrat full-width left">Notes Section(s)</span>
          <hr />
          <br />


          <div class="add-form no-padding"  [formGroup]="myGroup">
            <div formArrayName="section"> 
            <div  *ngFor="let section of sectionFormGroup ; let i = index" style="margin-top: 20px;">
              <div [formGroup]="sectionItemGroup(i)"> 
              <label>Section {{ i + 1 }}</label>
              <input matInput type="text" placeholder="Title" id="sectiontitleid_{{ i + 1 }}"
                class="add-form-input font-21 rubik" value=" " formControlName="sectiontitle"/>
              <span class="add-form-input-label">Paragraph</span>

              <!-- <textarea matInput rows="4" id="sectionparagraph_{{ i + 1 }}" class="add-form-textarea font-21 rubik"
                value="{{ section.paragraph }}"></textarea> -->
              <ckeditor #editor id="sectionparagraphid_{{ i + 1 }}" [editor]="Editor"
                formControlName="sectionparagraph"></ckeditor>
              </div>
          </div>
          </div>
        </div>
  
        </div>
        <div class="left full-width">
          <div class="section-panel" id="section-panel"></div>
          <button _ngcontent-dxo-c17="" class="action-button mat-raised-button mat-button-base" mat-raised-button=""
            style="width: 300px">
            <span class="mat-button-wrapper accordion" (click)="addSection()">
              Add Title/Paragraph*
              <img _ngcontent-dxo-c17="" src="assets/images/add-new.png" /></span>
            <div class="mat-button-ripple mat-ripple" matripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>&nbsp;&nbsp;

          <button _ngcontent-dxo-c17="" class="action-button mat-raised-button mat-button-base" mat-raised-button=""
            style="width: 340px">
            <span class="mat-button-wrapper accordion" (click)="removeSection()">
              Remove Title/Paragraph*
              <img _ngcontent-dxo-c17="" src="assets/images/minus.jpg" /></span>
            <div class="mat-button-ripple mat-ripple" matripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>

          <!-- <button
            _ngcontent-dxo-c17=""
            class="action-button mat-raised-button mat-button-base"
            mat-raised-button=""
            style="width: 260px"
          >
            <span class="mat-button-wrapper" (click)="addSection()">
              Add Paragraph*
              <img
                _ngcontent-dxo-c17=""
                src="assets/images/add-new.png" /><input
                _ngcontent-dxo-c17=""
                style="display: none"
                type="file"
            /></span>
            <div class="mat-button-ripple mat-ripple" matripple=""></div>
            <div class="mat-button-focus-overlay"></div></button
          > -->  
        </div>
        <!-- <div class="left full-width"><app-add-title-para></app-add-title-para></div> -->
        <!-- About client or List client -->
        <div *ngIf="!client?.id" class="left full-width">
          <br />
          <button _ngcontent-dxo-c17="" class="action-button mat-raised-button mat-button-base" mat-raised-button=""
            style="width: 300px">
            <span class="mat-button-wrapper" (click)="addClient()">
              About Client*
              <img _ngcontent-dxo-c17="" src="assets/images/add-new.png" /><input _ngcontent-dxo-c17=""
                style="display: none" type="file" /></span>
            <div class="mat-button-ripple mat-ripple" matripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button><br />
        </div>
        <div *ngIf="client?.id" class="full-width left top-bottom-margin pointer" (click)="addClient()">
          <span class="head-text montserrat">About {{ client.name }}</span>
          <hr />
          <span class="rubik font-21 word-break" [innerHTML]="client.info"></span><br /><br />
          <img src="{{ client?.logo }}" class="add-form-img" />
        </div>
        <div *ngIf="additionalLogo.length == 0" class="left full-width">
        <p>  <span class="head-text montserrat"  >Additional Logos</span> 
       <span style="float:right;margin-top:10px" (click)="addAdditionalLogo()">  <img width="20" src="assets/images/add-new.png" /> </span> 
      </p> 
      <hr />
      <p> No Additional logo added.</p>
          
        </div>
        <div *ngIf="additionalLogo.length > 0" class="full-width left top-bottom-margin pointer">
          <span class="head-text montserrat"  (click)="addAdditionalLogo()">Additional Logos</span> 
          <span style="float:right;margin-top:10px" (click)="addAdditionalLogo()">  <img width="20" src="assets/images/add-new.png" /> </span> 
          <hr /> <br/>
        <span *ngFor="let items of additionalLogo" style="background-color: azure;padding:10px;display:inline-block ; border-radius: 4px;position: relative; margin:10px;box-shadow: 2px 4px #4433;"> 
          <img [src]="items.logo_url" style="width:100px;height:100px" (click)="openAdditionalLogo(items)"  />
          <i style="position: absolute;top:-10px;right: 0px;" (click)="openDialog(items)">   <img width="16" src="assets/images/minus.jpg" /> </i>
        </span>
          
        </div>
        
        <div class="full-width left">
          <button mat-button class="done-btn right" style="width: 224px" (click)="preview(true)">
          SAVE & PREVIEW
          </button>
          <button mat-button class="save-btn right" (click)="save()">
            SAVE
          </button>
          <a href="/session-notes/add"><button mat-button class="reset-btn right">RESET</button></a>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>

 