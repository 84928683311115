<div class="hide-page"></div>
<mat-dialog-content>
  <div class="messageBody-centered-add-session-note">
    <mat-icon class="close-btn" (click)="closeDialog()">cancel</mat-icon>
    <span class="modal-head montserrat"> Add Moderator</span>
    <div class="messageBody-centered-add-session-note-content">
      <div
        *ngFor="let moderator of moderators; let i = index"
        class="add-moderator-box"
      >
        <div class="add-form-img left">
          <img
            class="session-note-mod-img"
            src="{{ moderator.image }}" id="modImage_{{ i + 1 }}"
            onerror="this.src='/assets/images/upload-pic-placeholder.png'"
            (click)="triggerUpload(i + 1)"
          />
          <input
            type="file"
            id="moderatorImage_{{ i + 1 }}"
            (change)="uploadFiles($event, i + 1)"
            style="display: none"
          />
          <input
            type="hidden"
            id="modId_{{ i + 1 }}"
            value="{{ moderator.id }}"
          />
        </div>

        <div class="add-form left">
          <input
            matInput
            type="text"
            placeholder="Name"
            id="sessionnotename_{{ i + 1 }}"
            class="add-form-input font-27"
            value="{{ moderator.name }}"
          />
          <input
            matInput
            type="text"
            placeholder="LinkedIn Url"
            id="linkedin_url_{{ i + 1 }}"
            class="add-form-input font-27"
            value="{{ moderator.linkedin_url }}"
          />
          <input
            matInput
            type="text"
            placeholder="Designation"
            id="sessionnotedesignation_{{ i + 1 }}"
            class="add-form-input font-27"
            value="{{ moderator.designation }}"
          />
        </div>
      </div>
      <div class="left" style="width: 50%">
        <button
          _ngcontent-dxo-c17=""
          class="action-button mat-raised-button mat-button-base"
          mat-raised-button=""
          style="
            width: 100%;
            background-color: transparent;
            margin-top: 2%;
            color: #ffffff;
          "
        >
          <span class="mat-button-wrapper" (click)="addMore()">
            Add More*
            <img
              _ngcontent-dxo-c17=""
              src="assets/images/plus-white.jpg" /><input
              _ngcontent-dxo-c17=""
              style="display: none"
              type="file"
          /></span>
          <div class="mat-button-ripple mat-ripple" matripple=""></div>
          <div class="mat-button-focus-overlay"></div></button
        ><br /><br />
      </div>
      <div class="left" style="width: 50%">
        <button
          _ngcontent-dxo-c17=""
          class="action-button mat-raised-button mat-button-base"
          mat-raised-button=""
          style="
            width: 100%;
            background-color: transparent;
            margin-top: 2%;
            color: #ffffff;
          "
        >
          <span class="mat-button-wrapper" (click)="removeModerator()">
            Remove Moderator*
            <img _ngcontent-dxo-c17="" src="assets/images/minus1.png" /><input
              _ngcontent-dxo-c17=""
              style="display: none"
              type="file"
          /></span>
          <div class="mat-button-ripple mat-ripple" matripple=""></div>
          <div class="mat-button-focus-overlay"></div></button
        ><br /><br />
      </div>
      <div class="full-width">
        <button mat-button class="done-btn right" (click)="done()">DONE</button>
        <button mat-button class="reset-btn right" (click)="reset()">
          RESET
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
