import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { LoginService } from "src/app/services/login.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  username = new FormControl("", Validators.required);
  password = new FormControl("", Validators.required);
  hidePassword = true;

  checkCredentialValidity = false;
  rememberCredential = new FormControl(false);

  constructor(
    private loginService: LoginService,
    private appRouter: Router,
    private snackBar: SnackbarService
  ) {}

  ngOnInit() {
    // if logged in, redirect to session notes page
    if (localStorage.getItem("user") != null) {
      let user = JSON.parse(localStorage["user"]);
      if (user?.id) {
        this.appRouter.navigate(["session-notes"]);
      }
    }

    // if credentials remembered, autopopulate them
    const storedUsername = localStorage.getItem("bfs_session_notes_unm");
    const storedPassword = localStorage.getItem("bfs_session_notes_pwd");
    if (storedUsername && storedPassword) {
      this.username = new FormControl(storedUsername, Validators.required);
      this.password = new FormControl(storedPassword, Validators.required);
      this.rememberCredential = new FormControl(true);
    }
  }

  login() {
    this.checkCredentialValidity = true;

    if (this.username.valid && this.password.valid) {
      const username = this.username.value;
      const password = this.password.value;

      this.loginService.loginUser(username, password).subscribe((data) => {
        if (data.status === true) {
          const userData = data as any;
          const userId = String(userData.id);

          if (localStorage) {
            localStorage.setItem("user", JSON.stringify(userData));
            this.appRouter.navigate(["/session-notes"]);
          } else {
            this.snackBar.snackBarDanger(
              "Internal Server Error! Please Contact Admin"
            );
          }
        } else {
          this.snackBar.snackBarDanger(data.message);
        }
      });
    }
  }

  remember_action() {
    this.checkCredentialValidity = true;

    if (this.rememberCredential.value === true) {
      if (this.username.valid && this.password.valid) {
        if (localStorage) {
          localStorage.setItem("bfs_session_notes_unm", this.username.value);
          localStorage.setItem("bfs_session_notes_pwd", this.password.value);
        } else {
          this.snackBar.snackBarDanger(
            "Internal Server Error! Please Contact Admin"
          );
        }
      } else {
        this.rememberCredential = new FormControl(false);
      }
    } else {
      localStorage.removeItem("bfs_session_notes_unm");
      localStorage.removeItem("bfs_session_notes_pwd");
    }
  }
}
