import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "../constants/urls";

@Injectable({
  providedIn: "root",
})
export class ParticipantService {
  constructor(private http: HttpClient) {}

  createParticipant(data: any) {
    return this.http.post<any>(API.CREATE_PARTICIPANT, data);
  }

  getParticipant(id: number) {
    return this.http.get<any>(API.GET_PARTICIPANT(id));
  }

  getParticipants(params?: any) {
    if (params) {
      params = { ...params, ...{ participant: true } };
    } else {
      params = { participant: true };
    }
    return this.http.get<any>(API.GET_SESSION_NOTES, { params });
  }

  listParticipants(params?: any) {
    return this.http.get<any>(API.GET_PARTICIPANTS, { params });
  }

  updateParticipant(id?: number, data?: any) {
    return this.http.patch<any>(API.UPDATE_PARTICIPANT(id), data);
  }

  deleteParticpant(id: number) {
    return this.http.delete<any>(API.DELETE_PARTICPANT(id));
  }
}
