<mat-sidenav-container class="main-container" autosize>
  <mat-sidenav #sidenav mode="side" opened class="main-sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <div class="progress-spinner" *ngIf="serviceResponseProgress">
    <mat-progress-spinner
      *ngIf="serviceResponseProgress"
      class="progress-spinner-margin"
      [color]="srpColor"
      [value]="srpValue"
    >
    </mat-progress-spinner>
  </div>

  <div class="main-sidenav-content">
    <h1>Participant Summary</h1>
    <div class="button-container">
      <mat-form-field
        appearance="outline"
        class="search-form-field"
        style="width: 50%"
      >
        <input
          matInput
          type="text"
          placeholder="Search Participant Summary (Enter 3 or more characters)"
          [formControl]="searchName"
          (keyup)="searchparticipantSummary()"
        />
        <img matSuffix src="assets/images/search.png" class="search-icon" />
      </mat-form-field>

      <span class="button-seperator">
        <button
          class="primary-button header-height"
          (click)="filter()"
          mat-raised-button
        >
          FILTER
        </button>
      </span>

      <span class="button-seperator">
        <button
          class="primary-button warning-button header-height"
          (click)="resetFilters()"
          mat-raised-button
        >
          RESET
        </button>
      </span>

      <span class="button-seperator">
        <button
          mat-raised-button
          class="action-button header-height"
          routerLink="/participant-summary/add"
        >
          ADD NEW
          <img src="assets/images/add-new.png" />
        </button>
      </span>
    </div>

    <div
      *ngIf="participantResults?.results?.length > 0"
      class="mat-tab-container"
    >
      <div class="mat-tab-content-details">
        <p *ngIf="participantResults.count == 0">No Records Found</p>
        <div
          class="booklet-flex-container booklet-flex-container-participant"
          *ngFor="let participant of participantResults.results"
        >
          <div class="left booklets booklets-participant" style="width: 950px">
            <div
              class="left"
              [routerLink]="[participant.id]"
              style="cursor: pointer"
            >
              <p
                class="
                  booklet-name
                  cut-text cut-text-participant
                  booklet-name-participant
                "
              >
                {{ participant.title }}
              </p>
            </div>
            <div class="left created-by-participant">
              <span>By: </span>
              <span class="booklet-created-by-participant">
                <b
                  >{{ participant.created_by }}</b
                >
              </span>
              
            </div>
          </div>

          <div
            class="action-button-container action-button-container-participant"
          >
            <button
              mat-raised-button
              class="edit-button"
              [routerLink]="['/participant-summary/', participant.id]"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <button
              mat-button
              class="freeze-button red"
              (click)="confirmDelete(participant.id, participant.title)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>

            <button
              mat-raised-button
              class="export-button export-button-participant"
              (click)="download(participant.id)"
            >
              <mat-icon>download</mat-icon>
            </button>
          </div>
        </div>
        <!-- <div class="paginator-button-container"> -->
        <div class="right" style="margin-top: 2%">
          <button
            mat-raised-button
            [disabled]="participantResults.previous === null"
            class="action-button"
            (click)="navigatePage(participantResults.previous)"
          >
            <mat-icon>navigate_before</mat-icon>PREVIOUS
          </button>
          <button
            mat-raised-button
            [disabled]="participantResults.next === null"
            class="action-button"
            (click)="navigatePage(participantResults.next)"
          >
            NEXT
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
