import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Participants, RemovedSections } from "src/app/constants/models";
import { ParticipantService } from "src/app/services/participant.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SessionNotesService } from "src/app/services/session-notes.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { API } from "src/app/constants/urls";

@Component({
  selector: "app-add-participant-summary",
  templateUrl: "./add-participant-summary.component.html",
  styleUrls: ["./add-participant-summary.component.css"],
})
export class AddParticipantSummaryComponent implements OnInit {
  // initializing 20 sections
  myGroup = new FormGroup({
    sectiontitle_1: new FormControl(""),
    sectionparagraph_1: new FormControl(""),
    sectiontitle_2: new FormControl(""),
    sectionparagraph_2: new FormControl(""),
    sectiontitle_3: new FormControl(""),
    sectionparagraph_3: new FormControl(""),
    sectiontitle_4: new FormControl(""),
    sectionparagraph_4: new FormControl(""),
    sectiontitle_5: new FormControl(""),
    sectionparagraph_5: new FormControl(""),
    sectiontitle_6: new FormControl(""),
    sectionparagraph_6: new FormControl(""),
    sectiontitle_7: new FormControl(""),
    sectionparagraph_7: new FormControl(""),
    sectiontitle_8: new FormControl(""),
    sectionparagraph_8: new FormControl(""),
    sectiontitle_9: new FormControl(""),
    sectionparagraph_9: new FormControl(""),
    sectiontitle_10: new FormControl(""),
    sectionparagraph_10: new FormControl(""),
    sectiontitle_11: new FormControl(""),
    sectionparagraph_11: new FormControl(""),
    sectiontitle_12: new FormControl(""),
    sectionparagraph_12: new FormControl(""),
    sectiontitle_13: new FormControl(""),
    sectionparagraph_13: new FormControl(""),
    sectiontitle_14: new FormControl(""),
    sectionparagraph_14: new FormControl(""),
    sectiontitle_15: new FormControl(""),
    sectionparagraph_15: new FormControl(""),
    sectiontitle_16: new FormControl(""),
    sectionparagraph_16: new FormControl(""),
    sectiontitle_17: new FormControl(""),
    sectionparagraph_17: new FormControl(""),
    sectiontitle_18: new FormControl(""),
    sectionparagraph_18: new FormControl(""),
    sectiontitle_19: new FormControl(""),
    sectionparagraph_19: new FormControl(""),
    sectiontitle_20: new FormControl(""),
    sectionparagraph_20: new FormControl(""),
  });

  // noteId: string = "add";
  noteId: any;
  sections: Participants[] = [{ title: "", paragraph: "" }];
  sectionsPost: Participants[] = [];
  removedSections: RemovedSections[] = [];

  selectedSessionNotes = new FormControl();


  notesMyControl: FormControl = new FormControl();
  filteredNotes: any[] = [];
  notes: any[] = [];

  public Editor = ClassicEditor;

  constructor(
    private route: ActivatedRoute,
    private participantService: ParticipantService,
    private notesService: SessionNotesService,
    private snackBar: SnackbarService
  ) {}

  ngOnInit(): void {
    // check if edit or add mode
    this.route.paramMap.subscribe((params) => {
      this.noteId = Number(params.get("id")) || "add";
    });

    // if edit, get session note, moderators, client & sections
    this.getNotes(this.noteId);
  }

  getNotes(noteId: string) {
    var httpParams;

    if (noteId == "add") {
      httpParams = { page_size: 5000, participant: false };
    } else {
      httpParams = { id: this.noteId };
    }
    this.notesService.get_session_notes(httpParams).subscribe((data: any) => {
      this.notes = data.results;
      console.log(this.notes)
      if (this.noteId !== "add") {
        // disable autosuggest
        // this.notesMyControl = new FormControl(this.notes[0].title);
        // const notesAutoSuggest = document.getElementById("notes") as HTMLInputElement;
        // notesAutoSuggest.setAttribute("disabled", "disabled");

        // autopopulate existing participants
        this.participantService
          .listParticipants({ notes_id: this.noteId })
          .subscribe((data: any) => {
            this.sections = data.results;
            for (var i = 1; i <= data.results.length; i++) {
              this.myGroup
                .get(`sectiontitle_${i}`)
                ?.setValue(data.results[i - 1]["title"]);
              this.myGroup
                .get(`sectionparagraph_${i}`)
                ?.setValue(data.results[i - 1]["paragraph"]);
            }
          });
      }
    });
  }

  filterSessionNotes() {
    this.filteredNotes = [];
    if (!this.notesMyControl.value) {
      this.filteredNotes = this.notes;
    } else {
      this.notes.forEach((option) => {
        if (
          option.title
            .toLowerCase()
            .includes(this.notesMyControl.value.toLowerCase())
            
        ) {
          this.filteredNotes.push(option);
          
        }
      });
      
    }
  }

  save(isPreview: boolean = false) {
    if (this.validate() === false) {
      return;
    }

    let i = 0;
    var rowSection;
    this.sections.forEach((eachSection) => {
      i += 1;
      var title = this.myGroup.value[`sectiontitle_${i}`].trim();
      var paragraph = this.myGroup.value[`sectionparagraph_${i}`].trim();

      if (paragraph !== "" && paragraph !== undefined) {
        if (eachSection.tmp === true) {
          rowSection = { title: title, paragraph: paragraph };
        } else {
          rowSection = {
            title: title,
            paragraph: paragraph,
            id: eachSection.id,
          };
        }
        this.sectionsPost.push(rowSection);
      }
    });

    let postData = {
      notes: this.noteId,
      sections: this.sectionsPost,
      removedSections: this.removedSections,
    };

    const formData = new FormData();
    formData.append("data", JSON.stringify(postData));

    // call update API for creating / updating participants for / of a client
    this.participantService
      .updateParticipant(+this.noteId, formData)
      .subscribe((result) => {
        if (result.id) {
          if (!isPreview) {
            this.snackBar.snackBarSuccess("Session Notes saved successfully");
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
        } else {
          this.snackBar.snackBarDanger("Error saving Participant Summary");
        }
      });
    return true;
  }

  selectNote(id: string) {
    this.noteId = id;
  }

  reset() {
    // this.title = new FormControl("");
    // this.content = new FormControl("");
    // this.notesMyControl = new FormControl("");
  }

  preview(isPreview: boolean = false) {
    const isSaved = this.save(isPreview);
    if (isSaved) {
      window.open(
        `${API.BACKEND_URL}notes/participant/${this.noteId}/download`,
        "_blank"
      );
    }
  }

  addSection() {
    if (this.validate() === false) {
      return;
    }

    let valid = true;
    const sections = this.sections.length;
    if (sections > 0) {
      var lastSectionTitle = this.myGroup.value[`sectiontitle_${sections}`];
      var lastSectionPara = this.myGroup.value[`sectionparagraph_${sections}`];

      if (!lastSectionPara || !lastSectionTitle) {
        valid = false;
        this.snackBar.snackBarDanger(
          "Please add one Participant Summary first."
        );
      }
    }
    if (valid) {
      var panel = document.getElementById("section-panel") as HTMLInputElement;
      panel.style.display = "block";
      var new_id = sections + 1;
      this.sections.push({
        id: new_id,
        title: "",
        paragraph: "",
        tmp: true,
      });
    }
  }

  removeSection() {
    if (this.sections.length == 0) {
      this.snackBar.snackBarWarning("Section does not exists");
      return false;
    }
    console.log(this.sections);
    this.removedSections.push({
      id: this.sections[this.sections.length - 1].id,
    });
    this.sections.pop();
    this.snackBar.snackBarSuccess("Section removed successfully");
    return true;
  }

  validate() {
    var paragraph = this.myGroup.value[`sectionparagraph_1`].trim();

    if (this.noteId == "" || this.noteId == "add") {
      this.snackBar.snackBarDanger(
        "Please select Session Note from the available dropdown"
      );
      return false;
    } else if (paragraph == "" || paragraph == undefined) {
      this.snackBar.snackBarDanger("Please add atleast one section");
      return false;
    }
    return true;
  }
}
